import React, { useState } from 'react'
import PropTypes from 'prop-types'

import InputGroupAppendDialog from 'src/components/Mix/InputGroupAppendDialog'
import InputGroup from 'src/components/Input/InputGroup'

import {
  thetaIcon,
  thetaMin,
  thetaStep,
  thetaUnit,
  thetaDecimalScale,
  phiIcon,
  phiMin,
  phiMax,
  phiStep,
  phiUnit,
  phiDecimalScale,
  gainIcon,
  gainStep,
  gainUnit,
  gainDecimalScale,
  getThetaPhiPhaseWarningText,
  getGainWarningText,
} from 'src/constants/beamformers'

const InputControl = ({
  deviceType,
  dragHVMode,
  thetaH,
  thetaV,
  phiH,
  phiV,
  beamCurrentGainH,
  beamCurrentGainV,
  thetaMaxH,
  thetaMaxV,
  beamGainMinH,
  beamGainMinV,
  beamGainMaxH,
  beamGainMaxV,
  isOffH,
  isOffV,
  isGainDisabled = false,
  handleInputChange,
  handleInputFocus,
}) => {
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       State        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------

  const theta = { horizon: thetaH, vertical: thetaV }
  const phi = { horizon: phiH, vertical: phiV }
  const beamCurrentGain = {
    horizon: beamCurrentGainH,
    vertical: beamCurrentGainV,
  }
  const thetaMax = { horizon: thetaMaxH, vertical: thetaMaxV }
  const beamGainMin = { horizon: beamGainMinH, vertical: beamGainMinV }
  const beamGainMax = { horizon: beamGainMaxH, vertical: beamGainMaxV }
  const isOff = { horizon: isOffH, vertical: isOffV }

  // ---- warning status
  const [thetaWarning, setThetaWarning] = useState({
    horizon: false,
    vertical: false,
  })
  const [phiWarning, setPhiWarning] = useState({
    horizon: false,
    vertical: false,
  })
  const [gainWarning, setGainWarning] = useState({
    horizon: false,
    vertical: false,
  })

  const hClassProps = {
    isActive: dragHVMode === 'horizon',
    isOff: isOff['horizon'],
  }
  const vClassProps = {
    isActive: dragHVMode === 'vertical',
    isOff: isOff['vertical'],
  }

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Props       ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  // ======================
  // warning text
  // ======================
  const thetaWarningText = hvMode =>
    getThetaPhiPhaseWarningText({
      min: thetaMin,
      max: thetaMax[hvMode],
      step: thetaStep,
    })

  const phiWarningText = hvMode =>
    getThetaPhiPhaseWarningText({
      min: phiMin,
      max: phiMax,
      step: phiStep,
    })

  const gainWarningText = hvMode =>
    getGainWarningText({
      min: beamGainMin[hvMode],
      max: beamGainMax[hvMode],
      step: gainStep,
    })

  // ======================
  // Input props
  // ======================
  const thetaInputProps = hvMode => ({
    className: 'w-full ',
    size: 'lg',
    icon: thetaIcon,
    unit: thetaUnit,
    step: thetaStep,
    loop: false,
    validMin: thetaMin,
    validMax: thetaMax[hvMode],
    decimalScale: thetaDecimalScale,
    value: String(theta[hvMode]),
    off: isOff[hvMode],
  })

  const phiInputProps = hvMode => ({
    className: 'w-full ',
    size: 'lg',
    icon: phiIcon,
    unit: phiUnit,
    step: phiStep,
    loop: true,
    validMin: phiMin,
    validMax: phiMax,
    decimalScale: phiDecimalScale,
    value: String(phi[hvMode]),
    off: isOff[hvMode],
  })

  const gainInputProps = hvMode => ({
    className: 'w-full',
    size: 'lg',
    icon: gainIcon,
    unit: gainUnit,
    step: gainStep,
    loop: false,
    validMin: beamGainMin[hvMode],
    validMax: beamGainMax[hvMode],
    decimalScale: gainDecimalScale,
    value: String(beamCurrentGain[hvMode]),
    off: isOff[hvMode],
    disabled: isGainDisabled,
  })

  // ======================
  // OverwriteElement
  // ======================
  const thetaDialogOverwriteElement = hvMode => (
    <div className={itemBox}>
      <InputGroup warning={true} disabled={true} {...thetaInputProps(hvMode)} />
      <span className={rangeText + ' text-yellow'}>Max {thetaMax[hvMode]}</span>
    </div>
  )
  const phiDialogOverwriteElement = hvMode => (
    <div className={itemBox}>
      <InputGroup warning={true} disabled={true} {...phiInputProps(hvMode)} />
      <span className={rangeText + ' text-yellow'}>Max {phiMax}</span>
    </div>
  )
  const gainDialogOverwriteElement = hvMode => (
    <div className={itemBox}>
      <InputGroup warning={true} disabled={true} {...gainInputProps(hvMode)} />
      <span className={rangeText + ' text-yellow'}>
        Range {beamGainMin[hvMode]} ~ {beamGainMax[hvMode]}
      </span>
    </div>
  )

  // ======================
  // Dialog props
  // ======================
  const thetaDialogProps = hvMode => ({
    dialogText: thetaWarningText(hvMode)[thetaWarning[hvMode]],
    placement: 'top',
    overwriteOffset: { top: 0, left: 0 },
    overwriteElements: thetaDialogOverwriteElement(hvMode),
    warning: thetaWarning[hvMode],
    setWarning: result =>
      setThetaWarning(prev => ({ ...prev, [hvMode]: result })),
    setFocus: () => handleInputFocus({ hvMode }),
    changeCallback: ({ value, isValid }) =>
      handleInputChange({ label: 'theta', hvMode, value, isValid }),
  })
  const phiDialogProps = hvMode => ({
    dialogText: phiWarningText(hvMode)[phiWarning[hvMode]],
    placement: 'top',
    overwriteOffset: { top: 0, left: 0 },
    overwriteElements: phiDialogOverwriteElement(hvMode),
    warning: phiWarning[hvMode],
    setWarning: result =>
      setPhiWarning(prev => ({ ...prev, [hvMode]: result })),
    setFocus: () => handleInputFocus({ hvMode }),
    changeCallback: ({ value, isValid }) =>
      handleInputChange({ label: 'phi', hvMode, value, isValid }),
  })
  const gainDialogProps = hvMode => ({
    dialogText: gainWarningText(hvMode)[gainWarning[hvMode]],
    placement: 'top',
    overwriteOffset: { top: 0, left: 0 },
    overwriteElements: gainDialogOverwriteElement(hvMode),
    warning: gainWarning[hvMode],
    setWarning: result =>
      setGainWarning(prev => ({ ...prev, [hvMode]: result })),
    setFocus: () => handleInputFocus({ hvMode }),
    changeCallback: ({ value, isValid }) =>
      handleInputChange({ label: 'gain', hvMode, value, isValid }),
  })

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div className={container}>
      <div className='flex flex-col gap-y-3'>
        <h6 className={subtitle(hClassProps)}>H-Polarization</h6>

        <div className={itemBox(hClassProps)}>
          <InputGroupAppendDialog
            {...thetaInputProps('horizon')}
            {...thetaDialogProps('horizon')}
          />
          <span className={rangeText}>Max {thetaMaxH}</span>
        </div>

        <div className={itemBox(hClassProps)}>
          <InputGroupAppendDialog
            {...phiInputProps('horizon')}
            {...phiDialogProps('horizon')}
          />
          <span className={rangeText}>Max {phiMax}</span>
        </div>

        <div className={itemBox(hClassProps)}>
          <InputGroupAppendDialog
            {...gainInputProps('horizon')}
            {...gainDialogProps('horizon')}
          />
          {!isGainDisabled && (
            <span className={rangeText}>
              Range {beamGainMinH} ~ {beamGainMaxH}
            </span>
          )}
        </div>
      </div>

      <div className='flex flex-col gap-y-3'>
        <h6 className={subtitle(vClassProps)}>V-Polarization</h6>

        <div className={itemBox(vClassProps)}>
          <InputGroupAppendDialog
            {...thetaInputProps('vertical')}
            {...thetaDialogProps('vertical')}
          />
          <span className={rangeText}>Max {thetaMaxV}</span>
        </div>

        <div className={itemBox(vClassProps)}>
          <InputGroupAppendDialog
            {...phiInputProps('vertical')}
            {...phiDialogProps('vertical')}
          />
          <span className={rangeText}>Max {phiMax}</span>
        </div>

        <div className={itemBox(vClassProps)}>
          <InputGroupAppendDialog
            {...gainInputProps('vertical')}
            {...gainDialogProps('vertical')}
          />
          {!isGainDisabled && (
            <span className={rangeText}>
              Range {beamGainMinV} ~ {beamGainMaxV}
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

export default InputControl

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------     Static CSS     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const container = `[BeamSteering-InputControl-container]
                    grid grid-cols-2 
                    gap-x-4 
                    `

const subtitle = ({ isActive, isOff }) =>
  `${!isOff && isActive ? 'text-teal' : 'text-light-5'} 
                                font-sm font-bold text-center duration-500`
const itemBox = ({ isActive, isOff }) => `
                                ${
                                  !isOff && isActive
                                    ? 'opacity-100 drop-shadow-md'
                                    : 'opacity-50 drop-shadow-2xl'
                                } 
                                flex flex-col w-full 
                                duration-500
                                `
const rangeText = `block pl-3 text-xs text-white/50 font-normal mt-1`

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Type  validate   -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
InputControl.propTypes = {
  theta: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  phi: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleInputChange: PropTypes.func,
}
