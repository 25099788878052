import React from 'react'
import { useDispatch } from 'react-redux'

import { polarSynthesisIcon as polarIcon } from 'src/constants/cloverCell'

import CommonSwitch from 'src/components/Switch/CommonSwitch'
import CommonSlider from 'src/components/Slider/CommonSlider'
import Mask from 'src/components/Mask'

import {
  container,
  h4,
  h6,
} from 'src/containers/operating/device/beamformers/style'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

import {
  cloverCellEvb_channel_commonGainSliderChange_watcher,
  cloverCellEvb_channel_commonGainChange_watcher,
  cloverCellEvb_channel_commonPhaseSliderChange_watcher,
  cloverCellEvb_channel_commonPhaseChange_watcher,
} from 'src/redux/actions/beamformers/cloverCellEvbAction'

import { BiLock } from 'react-icons/bi'
import { modalActions } from 'src/redux/slices/modal'

import { phaseStep, gainStep } from 'src/constants/beamformers'

const Polarization = props => {
  const dispatch = useDispatch()
  const { current } = useGetCurrentDeviceData()

  const rfMode = current.data.deviceControl.common.rfMode

  const polarData = [
    {
      icon: <></>,
      name: 'Not using',
      isActive: true,
      onClick: () => console.log('not using'),
    },
    {
      icon: <></>,
      name: 'Forward slash linear',
      isActive: false,
      onClick: () => console.log('not using'),
    },
    {
      icon: <></>,
      name: 'Backward slash linear',
      isActive: false,
      onClick: () => console.log('not using'),
    },
    {
      icon: <></>,
      name: 'Left-hand circular',
      isActive: false,
      onClick: () => console.log('not using'),
    },
    {
      icon: <></>,
      name: 'Right-hand circular',
      isActive: false,
      onClick: () => console.log('not using'),
    },
  ]

  return (
    <div className={container + ' relative'}>
      {rfMode === 'standby' && (
        <Mask className={maskClass + ' z-30'}>
          <span className='text-base font-bold'>Enabled in TX or RX mode.</span>
        </Mask>
      )}

      <Mask className={maskClass + ' z-10'}>
        <BiLock className='text-5xl' />
        <span className='text-base font-bold'>Premium feature</span>
      </Mask>

      {/* 設計師希望 title 跟 開關不要被 Premium feature Mask 擋住 */}
      {/* 所以加 absolute & z-20 */}
      <div className='absolute inset-x-4 z-20 flex items-center justify-between'>
        <h4 className={`text-base font-bold text-white`}>
          Polarization Presets
        </h4>

        <CommonSwitch
          type='text'
          onText='ON'
          offText='OFF'
          size='sm'
          on={false}
          disabled={false}
          onClick={() =>
            dispatch(
              modalActions.showModalOfCloverCellPolarizationPremiumContactTMYTEK()
            )
          }
        />
      </div>

      <div className='grid grid-cols-5 gap-x-2 mt-10 mb-4'>
        {polarData.map((e, i) => (
          <PolarButton
            key={`PolarSynthesis ${e.name}`}
            isDisabled={true}
            isActive={e.isActive}
            onClick={e.onClick}>
            <span className='text-2xl'>{polarIcon[e.name]}</span>
            <span className='h-7'>{e.name}</span>
          </PolarButton>
        ))}
      </div>

      <div className='grid grid-cols-2 gap-x-2'>
        <CommonControl hvMode='horizon' isDisabled={false} />
        <CommonControl hvMode='vertical' isDisabled={false} />
      </div>
    </div>
  )
}

Polarization.propTypes = {}

export default Polarization

const maskClass = `
                  flex flex-col 
                  rounded-md 
                  items-center justify-center 
                  gap-y-2 
                  text-light-4 
                  cursor-not-allowed select-none
                  `

const PolarButton = ({ isActive, isDisabled, onClick, ...props }) => {
  return (
    <div className={buttonClass(isActive, isDisabled)} onClick={onClick}>
      {props.children}
    </div>
  )
}

const buttonClass = (isActive, isDisabled) => `
                                ${
                                  isDisabled
                                    ? 'border-dark-5'
                                    : isActive
                                    ? 'border-teal'
                                    : 'border-light-5'
                                }
                                ${
                                  isDisabled
                                    ? 'text-dark-4 cursor-pointer'
                                    : 'cursor-not-allowed text-light-4 hover:bg-[rgba(194,194,194,0.2)]'
                                }
                                h-[100px] 
                                rounded-md 
                                text-xs text-center 
                                flex flex-col items-center justify-center 
                                gap-y-2 
                                border-1 border-solid 
                                bg-dark-1 
                                duration-500 
                                select-none 
                                `

const CommonControl = ({ hvMode, isDisabled }) => {
  const dispatch = useDispatch()
  const { current } = useGetCurrentDeviceData()

  const sn = current?.sn
  const rfMode = current.data.deviceControl.common.rfMode
  const currentBBoardData =
    current.data.deviceControl.channel[rfMode]?.[hvMode]?.boardData

  const commonCurrentGain = currentBBoardData?.[0]?.commonCurrentGain || 0
  const commonGainMin = currentBBoardData?.[0]?.commonGainMin || 0
  const commonGainMax = currentBBoardData?.[0]?.commonGainMax || 0
  const commonGainCenter = commonGainMax - (commonGainMax - commonGainMin) / 2

  const commonCurrentPhase = currentBBoardData?.[0]?.commonCurrentPhase || 0
  const commonPhaseMin = currentBBoardData?.[0]?.commonPhaseMin || 0
  const commonPhaseMax = currentBBoardData?.[0]?.commonPhaseMax || 0

  const titleHead = hvMode === 'horizon' ? 'H' : 'V'

  const handleCommonGainSliderChange = value =>
    dispatch(
      cloverCellEvb_channel_commonGainSliderChange_watcher({
        sn,
        hvMode,
        boardIndex: 0,
        value,
      })
    )
  const handleCommonGainSliderAfterChange = value =>
    dispatch(
      cloverCellEvb_channel_commonGainChange_watcher({
        sn,
        hvMode,
        boardIndex: 0,
        value,
        isValid: true,
      })
    )
  const handleCommonPhaseSliderChange = value =>
    dispatch(
      cloverCellEvb_channel_commonPhaseSliderChange_watcher({
        sn,
        hvMode,
        boardIndex: 0,
        value,
      })
    )
  const handleCommonPhaseSliderAfterChange = value =>
    dispatch(
      cloverCellEvb_channel_commonPhaseChange_watcher({
        sn,
        hvMode,
        boardIndex: 0,
        value,
        isValid: true,
      })
    )

  return (
    <div className='px-4 py-3 flex flex-col bg-white/10 rounded-md'>
      <h4 className={h4 + ' font-bold mb-2'}>{titleHead}-Polarization </h4>

      <h6 className={h6 + ' mb-1'}>Common Phase</h6>
      <CommonSlider
        disabled={isDisabled}
        value={commonCurrentPhase}
        step={phaseStep}
        min={commonPhaseMin}
        max={commonPhaseMax}
        onChange={handleCommonPhaseSliderChange}
        onAfterChange={handleCommonPhaseSliderAfterChange}
      />
      <div className={stepTextContainer}>
        <small className={small}>{commonPhaseMin}</small>
        <small className={small}>180</small>
        <small className={small}>{commonPhaseMax}</small>
      </div>

      <h6 className={h6 + ' mt-2 mb-1'}>Common Gain</h6>
      <CommonSlider
        value={commonCurrentGain}
        step={gainStep}
        min={commonGainMin}
        max={commonGainMax}
        disabled={isDisabled}
        onChange={handleCommonGainSliderChange}
        onAfterChange={handleCommonGainSliderAfterChange}
      />
      <div className={stepTextContainer}>
        <small className={small}>{commonGainMin}</small>
        <small className={small}>{commonGainCenter}</small>
        <small className={small}>{commonGainMax}</small>
      </div>
    </div>
  )
}

const stepTextContainer = `flex justify-between 
                            w-[calc(100%_+_18px)] 
                            -ml-[8px] mt-[2px] 
                            text-sm text-light-4
                            `
const small = `w-[30px] text-center`
