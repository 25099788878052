import React, { useState } from 'react'

import SwitchGroup from 'src/components/Switch/SwitchGroup'
import InputGroup from 'src/components/Input/InputGroup'
import InputGroupAppendDialog from 'src/components/Mix/InputGroupAppendDialog'

import {
  thetaIcon,
  thetaMin,
  thetaStep,
  thetaUnit,
  thetaDecimalScale,
  phiIcon,
  phiMin,
  phiMax,
  phiStep,
  phiUnit,
  phiDecimalScale,
  gainIcon,
  gainStep,
  gainUnit,
  gainDecimalScale,
  getThetaPhiPhaseWarningText,
  getGainWarningText,
} from 'src/constants/beamformers'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

import { getDeviceInfo } from 'src/funcs/getDeviceInfo'

const BeamItem = ({
  disabled,
  beamID,
  beam_config,
  rfMode,
  handleSteeringChange,
}) => {
  const { db, theta, phi } = beam_config
  const gain = db
  const { current } = useGetCurrentDeviceData()
  const { deviceControl } = current.data
  const deviceType = current?.deviceType

  const isBBoxLite = getDeviceInfo[deviceType]?.devName?.second === 'lite'

  const thetaMax = deviceControl?.common.lstAntennaData.find(
    e => e.name === deviceControl.common?.currentAntenna
  ).thetaMax

  const gainMin = deviceControl.steering[rfMode].beamGainMin
  const gainMax = deviceControl.steering[rfMode].beamGainMax
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       State        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- --------ㄚ---------- -----------------
  const [thetaWarning, setThetaWarning] = useState('')
  const [thetaFocus, setThetaFocus] = useState(false)
  const [phiWarning, setPhiWarning] = useState('')
  const [phiFocus, setPhiFocus] = useState(false)
  const [gainWarning, setGainWarning] = useState('')
  const [gainFocus, setGainFocus] = useState(false)

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Props       ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  // ======================
  // input
  // ======================
  const thetaInputProps = {
    className: 'max-w-[130px]',
    icon: thetaIcon,
    unit: thetaUnit,
    step: thetaStep,
    validMin: thetaMin,
    validMax: thetaMax,
    keydownMin: thetaMin,
    keydownMax: thetaMax,
    decimalScale: thetaDecimalScale,
    disabled,
    value: String(theta),
  }
  const phiInputProps = {
    className: 'max-w-[130px]',
    icon: phiIcon,
    unit: phiUnit,
    step: phiStep,
    validMin: phiMin,
    validMax: phiMax,
    keydownMin: phiMin,
    keydownMax: phiMax,
    decimalScale: phiDecimalScale,
    loop: true,
    disabled,
    value: String(phi),
  }
  const gainInputProps = {
    className: 'max-w-[130px]',
    icon: gainIcon,
    unit: gainUnit,
    step: gainStep,
    validMin: gainMin,
    validMax: gainMax,
    keydownMin: gainMin,
    keydownMax: gainMax,
    decimalScale: gainDecimalScale,
    disabled,
    value: String(gain),
  }

  // ======================
  // warning text
  // ======================
  const thetaWarningText = getThetaPhiPhaseWarningText({
    min: thetaMin,
    max: thetaMax,
    step: thetaStep,
  })

  const phiWarningText = getThetaPhiPhaseWarningText({
    min: phiMin,
    max: phiMax,
    step: phiStep,
  })

  const gainWarningText = getGainWarningText({
    min: gainMin,
    max: gainMax,
    step: gainStep,
  })
  // ======================
  // dialog
  // ======================
  const thetaDialogProps = {
    dialogText: thetaWarningText[thetaWarning],
    placement: 'top',
    overwriteOffset: { top: 0, left: 0 },
    overwriteElements: overwriteElement({
      inputProps: thetaInputProps,
      min: thetaMin,
      max: thetaMax,
    }),
    setFocus: result => setThetaFocus(result),
    warning: thetaWarning,
    setWarning: result => setThetaWarning(result),
    changeCallback: ({ value, isValid }) =>
      handleSteeringChange({ beamID, label: 'theta', value, isValid }),
  }

  const phiDialogProps = {
    dialogText: phiWarningText[phiWarning],
    placement: 'top',
    overwriteOffset: { top: 0, left: 0 },
    overwriteElements: overwriteElement({
      inputProps: phiInputProps,
      min: phiMin,
      max: phiMax,
    }),
    setFocus: result => setPhiFocus(result),
    warning: phiWarning,
    setWarning: result => setPhiWarning(result),
    changeCallback: ({ value, isValid }) =>
      handleSteeringChange({ beamID, label: 'phi', value, isValid }),
  }

  const gainDialogProps = {
    dialogText: gainWarningText[gainWarning],
    placement: 'top',
    overwriteOffset: { top: 0, left: 0 },
    overwriteElements: overwriteElement({
      inputProps: gainInputProps,
      min: gainMin,
      max: gainMax,
    }),
    setFocus: result => setGainFocus(result),
    warning: gainWarning,
    setWarning: result => setGainWarning(result),
    changeCallback: ({ value, isValid }) =>
      handleSteeringChange({ beamID, label: 'db', value, isValid }),
  }

  return (
    <div className='flex gap-x-2 items-center'>
      <div className={itemBox}>
        <InputGroupAppendDialog {...thetaInputProps} {...thetaDialogProps} />
        {thetaFocus && (
          <span className={rangeText}>
            Range {thetaMin} ~ {thetaMax}
          </span>
        )}
      </div>

      {isBBoxLite ? (
        <div className={itemBox}>
          <SwitchGroup
            className='w-[130px] '
            size='md'
            value={+phi}
            icon={phiIcon}
            text1={0}
            text2={180}
            unit='˚'
            disabled={disabled}
            onClick={() =>
              handleSteeringChange({
                beamID,
                label: 'phi',
                value: +phi === 0 ? 180 : 0,
                isValid: true,
              })
            }
          />
        </div>
      ) : (
        <div className={itemBox}>
          <InputGroupAppendDialog {...phiInputProps} {...phiDialogProps} />
          {phiFocus && (
            <span className={rangeText}>
              Range {phiMin} ~ {phiMax}
            </span>
          )}
        </div>
      )}

      <div className={itemBox}>
        <InputGroupAppendDialog {...gainInputProps} {...gainDialogProps} />
        {gainFocus && (
          <span className={rangeText}>
            Range {gainMin} ~ {gainMax}
          </span>
        )}
      </div>
    </div>
  )
}

BeamItem.propTypes = {}

export default BeamItem

const overwriteElement = ({ inputProps, min, max }) => (
  <div className='relative flex flex-col gap-y-1 -translate-y-[10px]'>
    <InputGroup
      className='w-[130px] h-[32px] mb-1'
      inputMin={-999} // 讓輸入低於 0 又不合法時，dialog 不會跳回0
      warning={true}
      disabled={true}
      {...inputProps}
    />
    <span className={rangeText}>
      Range {min} ~ {max}
    </span>
  </div>
)

const itemBox = `flex flex-col gap-y-1`
const rangeText = `block pl-3 text-xs text-white/50 font-normal`
