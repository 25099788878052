import { createAction } from '@reduxjs/toolkit'

export const bbox_initDevice_watcher = createAction(
  'deviceControl/bbox_initDevice_watcher'
)
export const bbox_noTable_Import_watcher = createAction(
  'deviceControl/bbox_noTable_Import_watcher'
)

// device controller - common
export const bbox_common_antennaSelect_watcher = createAction(
  'deviceControl/bbox_common_antennaSelect_watcher'
)
export const bbox_common_antennaImport_watcher = createAction(
  'deviceControl/bbox_common_antennaImport_watcher'
)
export const bbox_common_rfModeSwitch_watcher = createAction(
  'deviceControl/bbox_common_rfModeSwitch_watcher'
)
export const bbox_common_freqSelect_watcher = createAction(
  'deviceControl/bbox_common_freqSelect_watcher'
)
export const bbox_common_freqAfterImport_watcher = createAction(
  'deviceControl/bbox_common_freqAfterImport_watcher'
)

export const bbox_customAntennaModal_createClick_watcher = createAction(
  'modalEvent/bbox_customAntennaModal_createClick_watcher'
)
export const bbox_customAntennaModal_leaveEditMode_watcher = createAction(
  'modalEvent/bbox_customAntennaModal_leaveEditMode_watcher'
)
export const bbox_customAntennaModal_deleteClick_watcher = createAction(
  'modalEvent/bbox_customAntennaModal_deleteClick_watcher'
)
export const bbox_customAntennaModal_deleteChildModal_deleteClick_watcher =
  createAction(
    'modalEvent/bbox_customAntennaModal_deleteChildModal_deleteClick_watcher'
  )
export const bbox_customAntennaModal_deleteChildModal_cancelClick_watcher =
  createAction('modalEvent/caDeleteChildModalCancelClick')
export const bbox_customAntennaModal_usingNotDeleteChildModal_okClick_watcher =
  createAction(
    'modalEvent/bbox_customAntennaModal_usingNotDeleteChildModal_okClick_watcher'
  )
export const bbox_customAntennaModal_saveClick_watcher = createAction(
  'modalEvent/bbox_customAntennaModal_saveClick_watcher'
)
export const bbox_customAntennaModal_exportClick_watcher = createAction(
  'modalEvent/bbox_customAntennaModal_exportClick_watcher'
)

// device controller - channel
export const bbox_channel_allPowerSwitch_watcher = createAction(
  'deviceControl/bbox_channel_allPowerSwitch_watcher'
)
export const bbox_channel_singlePowerChange_watcher = createAction(
  'deviceControl/bbox_channel_singlePowerChange_watcher'
)
export const bbox_channel_commonGainSliderChange_watcher = createAction(
  'deviceControl/bbox_channel_commonGainSliderChange_watcher'
)
export const bbox_channel_commonGainChange_watcher = createAction(
  'deviceControl/bbox_channel_commonGainChange_watcher'
)
export const bbox_channel_singleGainChange_watcher = createAction(
  'deviceControl/bbox_channel_singleGainChange_watcher'
)
export const bbox_channel_singlePhaseChange_watcher = createAction(
  'deviceControl/bbox_channel_singlePhaseChange_watcher'
)

// device controller - beam steering
export const bbox_steering_thetaPhiChange_watcher = createAction(
  'deviceControl/bbox_steering_thetaPhiChange_watcher'
)
export const bbox_steering_gainChange_watcher = createAction(
  'deviceControl/bbox_steering_gainChange_watcher'
)
export const bbox_steering_socketEmitting_watcher = createAction(
  'deviceControl/bbox_steering_socketEmitting_watcher'
)
export const bbox_steering_3DCameraChange_watcher = createAction(
  'deviceControl/bbox_steering_3DCameraChange_watcher'
)
export const bbox_steering_channelModeMaskClose_watcher = createAction(
  'deviceControl/steeringChannelModeMaskClose'
)

// beam config editor
export const bbox_deviceDataCurrentConfig_update_watcher = createAction(
  'deviceData/bbox_deviceDataCurrentConfig_update_watcher'
)

export const bbox_spi_configSave_watcher = createAction(
  'deviceControl/bbox_spi_configSave_watcher'
)

export const bbox_spi_switchMethod_watcher = createAction(
  'deviceControl/bbox_spi_switchMethod_watcher'
)

export const bbox_spi_configImport_watcher = createAction(
  'deviceControl/bbox_spi_configImport_watcher'
)

export const bbox_spi_configExport_watcher = createAction(
  'deviceControl/bbox_spi_configExport_watcher'
)

export const bbox_spi_configClear_watcher = createAction(
  'beamConfigEditor/bbox_spi_configClear_watcher'
)
export const bbox_configClearCheckModal_clearClick_watcher = createAction(
  'modalEvent/bbox_configClearCheckModal_clearClick_watcher'
)
export const bbox_configClearCheckModal_cancelClick_watcher = createAction(
  'modalEvent/bbox_configClearCheckModal_cancelClick_watcher'
)
export const bbox_configFailedToImportNoMatchedAntennaModal_okClick_watcher =
  createAction(
    'modalEvent/bbox_configFailedToImportNoMatchedAntennaModal_okClick_watcher'
  )
export const bbox_configImportNewSettingsModal_noClick_watcher = createAction(
  'modalEvent/bbox_configImportNewSettingsModal_noClick_watcher'
)
export const bbox_configImportNewSettingsModal_importClick_watcher =
  createAction(
    'modalEvent/bbox_configImportNewSettingsModal_importClick_watcher'
  )
export const bbox_configUnsavedChangesReminderModal_exportOnlyClick_watcher =
  createAction(
    'modalEvent/bbox_configUnsavedChangesReminderModal_exportOnlyClick_watcher'
  )
export const bbox_configUnsavedChangesReminderModal_exportAndSaveClick_watcher =
  createAction(
    'modalEvent/bbox_configUnsavedChangesReminderModal_exportAndSaveClick_watcher'
  )
