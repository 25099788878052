import { all, fork, take, throttle } from 'redux-saga/effects'

// action

import {
  bbox_initDevice_watcher,
  bbox_noTable_Import_watcher,
  bbox_deviceDataCurrentConfig_update_watcher,
  bbox_spi_configSave_watcher,
  bbox_spi_switchMethod_watcher,
  bbox_spi_configImport_watcher,
  bbox_spi_configExport_watcher,
  bbox_spi_configClear_watcher,
  bbox_common_antennaSelect_watcher,
  bbox_common_antennaImport_watcher,
  bbox_common_rfModeSwitch_watcher,
  bbox_common_freqSelect_watcher,
  bbox_common_freqAfterImport_watcher,
  bbox_channel_allPowerSwitch_watcher,
  bbox_channel_singlePowerChange_watcher,
  bbox_channel_commonGainSliderChange_watcher,
  bbox_channel_commonGainChange_watcher,
  bbox_channel_singleGainChange_watcher,
  bbox_channel_singlePhaseChange_watcher,
  bbox_steering_thetaPhiChange_watcher,
  bbox_steering_gainChange_watcher,
  bbox_steering_3DCameraChange_watcher,
  bbox_steering_channelModeMaskClose_watcher,
  bbox_customAntennaModal_createClick_watcher,
  bbox_customAntennaModal_leaveEditMode_watcher,
  bbox_customAntennaModal_deleteClick_watcher,
  bbox_customAntennaModal_saveClick_watcher,
  bbox_customAntennaModal_exportClick_watcher,
} from 'src/redux/actions/beamformers/bboxAction'

// handlers
import {
  init as handleInit,
  initNoTableAfterImport as handleInitNoTableAfterImport,
} from 'src/redux/sagas/handlers/event/beamformers/general/init'
import {
  commonRfModeSwitch as handleCommonRfModeSwitch,
  commonFreqSelect as handleCommonFreqSelect,
  commonFreqAfterImport as handleCommonFreqAfterImport,
} from 'src/redux/sagas/handlers/event/beamformers/general/common'

import {
  commonAntennaSelect as handleCommonAntennaSelect,
  commonAntennaImport as handleCommonAntennaImport,
  customAntennaCreate as handleCustomAntennaCreate,
  customAntennaLeaveEdit as handleCustomAntennaLeaveEdit,
  customAntennaDelete as handleCustomAntennaDelete,
  customAntennaSave as handleCustomAntennaSave,
  customAntennaExport as handleCustomAntennaExport,
} from 'src/redux/sagas/handlers/event/beamformers/bbox/common'

import {
  channelAllPower as handleChannelAllPower,
  channelSinglePower as handleChannelSinglePower,
  channelCommonGainSliderChange as handleChannelCommonGainSliderChange,
  channelCommonGainChange as handleChannelCommonGainChange,
  channelSingleGain as handleChannelSingleGain,
  channelSinglePhase as handleChannelSinglePhase,
} from 'src/redux/sagas/handlers/event/beamformers/bbox/channel'

import {
  steeringSocketEmitting,
  steeringChannelModeMaskClose as handleSteeringChannelModeMaskClose,
  steeringThetaPhiChange as handleSteeringThetaPhiChange,
  steering3DCameraChange as handleSteering3DCameraChange,
  steeringGainChange as handleSteeringGainChange,
} from 'src/redux/sagas/handlers/event/beamformers/bbox/steering'

import {
  updateDeviceDataCurrentConfig as handleUpdateDeviceDataCurrentConfig,
  spiSaveConfig as handleSpiSaveConfig,
  spiSwitchMethod as handleSpiSwitchMethod,
  spiImportConfig as handleSpiImportConfig,
  spiExportConfig as handleSpiExportConfig,
  configClear as handleConfigClear,
} from 'src/redux/sagas/handlers/event/beamformers/bbox/beamConfigEditor'

import { devLog } from 'src/funcs/tools'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------       General      -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
function* initBbox() {
  while (true) {
    const action = yield take(bbox_initDevice_watcher.type)
    // const { sn } = action.payload
    devLog('[watcher] initBbox', action.payload)
    yield fork(handleInit, action.payload)
  }
}
function* initBboxNoTableAfterImport() {
  while (true) {
    const action = yield take(bbox_noTable_Import_watcher.type)
    devLog('[watcher] initBboxNoTableAfterImport', action.payload)
    yield fork(handleInitNoTableAfterImport, action.payload)
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------       Common       -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
function* commonAntennaSelect() {
  while (true) {
    const action = yield take(bbox_common_antennaSelect_watcher.type)
    devLog('[watcher] commonAntennaSelect', action.payload)
    yield fork(handleCommonAntennaSelect, action.payload)
  }
}
function* commonAntennaImport() {
  while (true) {
    const action = yield take(bbox_common_antennaImport_watcher.type)
    devLog('[watcher] commonAntennaImport', action.payload)
    yield fork(handleCommonAntennaImport, action.payload)
  }
}
function* commonRfModeSwitch() {
  while (true) {
    const action = yield take(bbox_common_rfModeSwitch_watcher.type)
    devLog('[watcher] commonRfModeSwitch', action.payload)
    yield fork(handleCommonRfModeSwitch, action.payload)
  }
}
function* commonFreqSelect() {
  while (true) {
    const action = yield take(bbox_common_freqSelect_watcher.type)
    devLog('[watcher] commonFreqSelect', action.payload)
    yield fork(handleCommonFreqSelect, action.payload)
  }
}
function* commonFreqAfterImport() {
  while (true) {
    const action = yield take(bbox_common_freqAfterImport_watcher.type)
    devLog('[watcher] commonFreqAfterImport', action.payload)
    yield fork(handleCommonFreqAfterImport, action.payload)
  }
}

// custom antenna
function* customAntennaCreate() {
  while (true) {
    const action = yield take(bbox_customAntennaModal_createClick_watcher.type)
    devLog('[watcher] customAntennaCreate', action.payload)
    yield fork(handleCustomAntennaCreate, action.payload)
  }
}
function* customAntennaLeaveEdit() {
  while (true) {
    const action = yield take(
      bbox_customAntennaModal_leaveEditMode_watcher.type
    )
    devLog('[watcher] customAntennaLeaveEdit', action.payload)
    yield fork(handleCustomAntennaLeaveEdit, action.payload)
  }
}
function* customAntennaDelete() {
  while (true) {
    const action = yield take(bbox_customAntennaModal_deleteClick_watcher.type)
    devLog('[watcher] customAntennaDelete', action.payload)
    yield fork(handleCustomAntennaDelete, action.payload)
  }
}
function* customAntennaSave() {
  while (true) {
    const action = yield take(bbox_customAntennaModal_saveClick_watcher.type)
    devLog('[watcher] customAntennaSave', action.payload)
    yield fork(handleCustomAntennaSave, action.payload)
  }
}
function* customAntennaExport() {
  while (true) {
    const action = yield take(bbox_customAntennaModal_exportClick_watcher.type)
    devLog('[watcher] customAntennaExport', action.payload)
    yield fork(handleCustomAntennaExport, action.payload)
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------       Channel      -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
function* channelAllPower() {
  while (true) {
    const action = yield take(bbox_channel_allPowerSwitch_watcher.type)
    devLog('[watcher] channelAllPower', action.payload)
    yield fork(handleChannelAllPower, action.payload)
  }
}

function* channelSinglePower() {
  while (true) {
    const action = yield take(bbox_channel_singlePowerChange_watcher.type)
    devLog('[watcher] channelSinglePower', action.payload)
    yield fork(handleChannelSinglePower, action.payload)
  }
}

function* channelCommonGainChange() {
  while (true) {
    const action = yield take(bbox_channel_commonGainSliderChange_watcher.type)
    devLog('[watcher] channelCommonGainChange', action.payload)
    yield fork(handleChannelCommonGainSliderChange, action.payload)
  }
}
function* channelCommonGainAfterChange() {
  while (true) {
    const action = yield take(bbox_channel_commonGainChange_watcher.type)
    devLog('[watcher] channelCommonGainAfterChange', action.payload)
    yield fork(handleChannelCommonGainChange, action.payload)
  }
}

function* channelSingleGain() {
  while (true) {
    const action = yield take(bbox_channel_singleGainChange_watcher.type)
    devLog('[watcher] channelSingleGain', action.payload)
    yield fork(handleChannelSingleGain, action.payload)
  }
}

function* channelSinglePhase() {
  while (true) {
    const action = yield take(bbox_channel_singlePhaseChange_watcher.type)
    devLog('[watcher] channelSinglePhase', action.payload)
    yield fork(handleChannelSinglePhase, action.payload)
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------    Beam Steering   -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
function* steeringChannelModeMaskClose() {
  while (true) {
    const action = yield take(bbox_steering_channelModeMaskClose_watcher.type)
    devLog('[watcher] steeringRemoveMaskForChannelChanged', action.payload)
    yield fork(handleSteeringChannelModeMaskClose, action.payload)
  }
}

function* steeringThetaPhiChange() {
  while (true) {
    const action = yield take(bbox_steering_thetaPhiChange_watcher.type)
    devLog('[watcher] steeringThetaPhiChange', action.payload)
    yield fork(handleSteeringThetaPhiChange, action.payload)
  }
}

function* steering3DCameraChange() {
  yield throttle(
    1000,
    bbox_steering_3DCameraChange_watcher,
    handleSteering3DCameraChange
  )
}

function* steeringGainChange() {
  while (true) {
    const action = yield take(bbox_steering_gainChange_watcher.type)
    devLog('[watcher] steeringGainChange', action.payload)
    yield fork(handleSteeringGainChange, action.payload)
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* ----------------- Beam Config Editor -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
function* updateDeviceDataCurrentConfig() {
  while (true) {
    const action = yield take(bbox_deviceDataCurrentConfig_update_watcher.type)
    devLog('[watcher] updateDeviceDataCurrentConfig', action.payload)
    yield fork(handleUpdateDeviceDataCurrentConfig, action.payload)
  }
}

function* spiSaveConfig() {
  while (true) {
    const action = yield take(bbox_spi_configSave_watcher.type)
    devLog('[watcher] spiSaveConfig', action.payload)
    yield fork(handleSpiSaveConfig, action.payload)
  }
}

function* spiSwitchMethod() {
  yield throttle(1000, bbox_spi_switchMethod_watcher, handleSpiSwitchMethod)
}

function* spiImportConfig() {
  while (true) {
    const action = yield take(bbox_spi_configImport_watcher.type)
    devLog('[watcher] spiImportConfig', action.payload)
    yield fork(handleSpiImportConfig, action.payload)
  }
}

function* spiExportConfig() {
  while (true) {
    const action = yield take(bbox_spi_configExport_watcher.type)
    devLog('[watcher] spiExportConfig', action.payload)
    yield fork(handleSpiExportConfig, action.payload)
  }
}

function* configClear() {
  while (true) {
    const action = yield take(bbox_spi_configClear_watcher.type)
    devLog('[watcher] configClear', action.payload)
    yield fork(handleConfigClear, action.payload)
  }
}

export default function* bboxWatchers() {
  yield all([
    initBbox(),
    initBboxNoTableAfterImport(),

    // beam config editor
    updateDeviceDataCurrentConfig(),
    spiSaveConfig(),
    spiSwitchMethod(),
    spiImportConfig(),
    spiExportConfig(),
    configClear(),

    // device controller - common
    commonAntennaSelect(),
    commonAntennaImport(),
    commonRfModeSwitch(),
    commonFreqSelect(),
    commonFreqAfterImport(),

    customAntennaCreate(),
    customAntennaLeaveEdit(),
    customAntennaDelete(),
    customAntennaSave(),
    customAntennaExport(),

    // device controller - channel
    channelAllPower(),
    channelSinglePower(),
    channelCommonGainChange(),
    channelCommonGainAfterChange(),
    channelSingleGain(),
    channelSinglePhase(),

    // device controller - steering
    steeringSocketEmitting(),

    steeringChannelModeMaskClose(),
    steeringThetaPhiChange(),
    steering3DCameraChange(),

    steeringGainChange(),
  ])
}
