import React from 'react'
import { Link } from 'react-router-dom'

import Item from 'src/containers/operating/DeviceInventory/Item'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'
import useGetCurrentMode from 'src/hooks/useGetCurrentMode'

import { getDeviceInfo, getOfficialFullName } from 'src/funcs/getDeviceInfo'

const DeviceInventory = () => {
  const { currentMode } = useGetCurrentMode()

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       State        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------

  const { deviceData, current } = useGetCurrentDeviceData()

  const devNameFirst = deviceType => getDeviceInfo[deviceType].devName.first

  const officialName = deviceType => getOfficialFullName(deviceType)

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------

  return (
    <aside className={containerClass}>
      <div className={asideHeaderAndFooter} />

      <div className={mainClass}>
        {Object.entries(deviceData).map(([sn, { info }]) => (
          <Link
            key={'Operating Menu Item For Online Default ' + sn}
            to={`/operating/${currentMode}/${devNameFirst(
              info.deviceType
            )}/${sn}`}>
            <Item
              name={officialName(info.deviceType)}
              sn={sn}
              deviceType={info.deviceType}
              hardwareVersion={info.versionInfo?.hardware}
              isSelect={sn === current.sn}
              layout='col'
            />
          </Link>
        ))}

        <Item />
      </div>

      <div className={asideHeaderAndFooter} />
    </aside>
  )
}

DeviceInventory.propTypes = {}

export default DeviceInventory

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------     Static CSS     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const containerClass = `[DeviceInventory-containerClass] 
                        w-[224px] 
                        lg:w-[156px] 
                        2xl:w-[224px] 
                        h-full 
                        bg-dark-2 
                        lg:bg-light-4/10 
                        backdrop-blur 
                        flex flex-col 
                        shrink-0 
                        rounded-[6px]
                        `

const mainClass = `[DeviceInventory-mainClass] 
                  h-full
                  flex 
                  flex-col 
                  gap-y-2 
                  overflow-y-scroll 
                  overflow-x-hidden 
                  scrollbarHidden 
                  `

const asideHeaderAndFooter = `[DeviceInventory-asideHeaderAndFooter] w-full h-12 bg-dark-2`
