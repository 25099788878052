import { current } from '@reduxjs/toolkit'

const beamformers = {
  // beamformers - bbox - beamConfigEditor - editBeam
  showModalOfBBoxEditBeam: (state, action) => {
    const { sn, rfMode, beamID, isChanged } = action.payload
    state.normal = {
      type: 'BEAM_CONFIG_EDITOR',
      props: {
        status: 'EditBeam',
        sn,
        rfMode,
        beamID,
        isChanged: isChanged || false,
      },
    }
    return state
  },
  setBeamIDFromEditBeam: (state, action) => {
    const { beamID } = action.payload
    state.normal = {
      type: 'BEAM_CONFIG_EDITOR',
      props: { ...current(state).normal.props, status: 'EditBeam', beamID },
    }
    return state
  },
  setIsChangeFromEditBeam: (state, action) => {
    const { isChanged } = action.payload
    state.normal = {
      type: 'BEAM_CONFIG_EDITOR',
      props: { ...current(state).normal.props, status: 'EditBeam', isChanged },
    }
    return state
  },

  // beamformers - bbox - beamConfigEditor - import
  showModalOfBBoxFailedToImportForNoMatchedAntenna: (state, action) => {
    const { bindAntenna } = action.payload
    state.normal = {
      type: 'BEAM_CONFIG_EDITOR',
      props: {
        status: 'FailedToImportForNoMatchedAntenna',
        bindAntenna,
      },
    }
    return state
  },

  showModalOfBBoxImportNewSettings: (state, action) => {
    const { targetFreq, targetAntenna } = action.payload
    state.normal = {
      type: 'BEAM_CONFIG_EDITOR',
      props: {
        status: 'ImportNewSettings',
        targetFreq,
        targetAntenna,
      },
    }
    return state
  },

  showModalOfBBoxFailedToImportForIncorrectFormat: (state, action) => {
    state.normal = {
      type: 'BEAM_CONFIG_EDITOR',
      props: {
        status: 'FailedToImportForIncorrectFormat',
      },
    }
    return state
  },

  showModalOfBBoxFailedToImportForIncorrectSn: (state, action) => {
    const { currentSn, bindSn } = action.payload
    state.normal = {
      type: 'BEAM_CONFIG_EDITOR',
      props: {
        status: 'FailedToImportForIncorrectSn',
        currentSn,
        bindSn,
      },
    }
    return state
  },

  // beamformers - bbox - beamConfigEditor - export
  showModalOfBBoxConfigExportUnsavedChanges: (state, action) => {
    state.normal = {
      type: 'BEAM_CONFIG_EDITOR',
      props: {
        status: 'UnsavedChangesReminder',
        isSaveLoading: false,
      },
    }
    return state
  },
  setConfigExportUnsavedChangesIsSaveLoading: (state, action) => {
    const { isSaveLoading } = action.payload
    state.normal = {
      type: 'BEAM_CONFIG_EDITOR',
      props: {
        status: 'UnsavedChangesReminder',
        isSaveLoading,
      },
    }
    return state
  },
  // beamformers - bbox - beamConfigEditor - Current Method Switching
  showModalOfBBoxConfigCurrentMethodSwitching: (state, action) => {
    state.normal = {
      type: 'BEAM_CONFIG_EDITOR',
      props: {
        status: 'CurrentMethodSwitching',
      },
    }
    return state
  },

  // beamformers - bbox - beamConfigEditor - save
  showModalOfBBoxConfigFailedToSaveForNoAntennaAndBeamExist: (
    state,
    action
  ) => {
    state.normal = {
      type: 'BEAM_CONFIG_EDITOR',
      props: {
        status: 'FailedToSaveForNoAntennaAndBeamExist',
      },
    }
    return state
  },
  showModalOfBBoxConfigFailedToSaveForGeneral: (state, action) => {
    state.normal = {
      type: 'BEAM_CONFIG_EDITOR',
      props: {
        status: 'FailedToSaveForGeneral',
      },
    }
    return state
  },

  // beamformers - bbox - deviceController - import antenna

  // beamformers - bbox - deviceController - save to config
  showModalOfBBoxSaveToConfig: (state, action) => {
    const sn = action.payload?.sn
    state.normal = {
      type: 'SAVE_TO_BEAM_CONFIG',
      props: {
        sn,
      },
    }
    return state
  },

  // beamformers - bbox - deviceController - CA (custom antenna)
  showModalOfBBoxCA: (state, action) => {
    const sn = action.payload?.sn
    state.normal = {
      type: 'BBOX_CUSTOM_ANTENNA',
      props: {
        sn,
        selectIdList: [],
        editIsChanged: false,
        editIsCreate: false,
        editTemporaryData: {},
        childModalType: '',
      },
    }
    return state
  },

  // 進入編輯模式
  // 如果是按 create 過來的，editIsCreate = true
  setModalOfBBoxCAToEdit: (state, action) => {
    const { temporaryData, editIsCreate } = action.payload
    state.normal = {
      type: 'BBOX_CUSTOM_ANTENNA',
      props: {
        ...current(state).normal.props,
        editIsCreate,
        editTemporaryData: temporaryData,
      },
    }
    return state
  },

  // 離開編輯模式用這個
  setModalOfBBoxCALeaveEdit: (state, action) => {
    state.normal = {
      type: 'BBOX_CUSTOM_ANTENNA',
      props: {
        ...current(state).normal.props,
        editIsChanged: false,
        editIsCreate: false,
        editSaveIsRequesting: false,
        editDeleteRequesting: false,
        editTemporaryData: {},
        childModalType: '',
      },
    }
    return state
  },

  // 編輯模式時，畫面上輸入後更新 temporaryData 用這個
  setTemporaryDataForModalOfBBoxCA: (state, action) => {
    const editTemporaryData = action.payload?.editTemporaryData
    state.normal = {
      type: 'BBOX_CUSTOM_ANTENNA',
      props: {
        ...current(state).normal.props,
        editIsChanged: true,
        editTemporaryData,
      },
    }
    return state
  },

  setSelectIdListForModalOfBBoxCA: (state, action) => {
    const selectIdList = action.payload?.selectIdList
    state.normal = {
      type: 'BBOX_CUSTOM_ANTENNA',
      props: {
        ...current(state).normal.props,
        selectIdList,
      },
    }
    return state
  },

  setIsChangedForModalOfBBoxCA: (state, action) => {
    const editIsChanged = action.payload?.editIsChanged
    state.normal = {
      type: 'BBOX_CUSTOM_ANTENNA',
      props: {
        ...current(state).normal.props,
        editIsChanged,
      },
    }
    return state
  },

  setEditSaveIsRequestingForModalOfBBoxCA: (state, action) => {
    const editSaveIsRequesting = action.payload?.editSaveIsRequesting
    state.normal = {
      type: 'BBOX_CUSTOM_ANTENNA',
      props: {
        ...current(state).normal.props,
        editSaveIsRequesting,
      },
    }
    return state
  },

  setEditDeleteIsRequestingForModalOfBBoxCA: (state, action) => {
    const editDeleteRequesting = action.payload?.editDeleteRequesting
    state.normal = {
      type: 'BBOX_CUSTOM_ANTENNA',
      props: {
        ...current(state).normal.props,
        editDeleteRequesting,
      },
    }
    return state
  },

  showChildModalOfBBoxCAListModeDeleteCheck: (state, action) => {
    state.normal = {
      type: 'BBOX_CUSTOM_ANTENNA',
      props: {
        ...current(state).normal.props,
        childModalType: 'listModeDeleteCheck',
      },
    }
    return state
  },

  showChildModalOfBBoxCAEditModeDeleteCheck: (state, action) => {
    state.normal = {
      type: 'BBOX_CUSTOM_ANTENNA',
      props: {
        ...current(state).normal.props,
        childModalType: 'editModeDeleteCheck',
      },
    }
    return state
  },

  showChildModalOfBBoxCALeaveEditCheck: (state, action) => {
    state.normal = {
      type: 'BBOX_CUSTOM_ANTENNA',
      props: {
        ...current(state).normal.props,
        childModalType: 'leaveEditCheck',
      },
    }
    return state
  },

  showChildModalOfBBoxCAUsingNotDeleteCheck: (state, action) => {
    state.normal = {
      type: 'BBOX_CUSTOM_ANTENNA',
      props: {
        ...current(state).normal.props,
        childModalType: 'usingNotDeleteCheck',
      },
    }
    return state
  },

  clearChildModalOfBBoxCA: (state, action) => {
    state.normal = {
      type: 'BBOX_CUSTOM_ANTENNA',
      props: {
        ...current(state).normal.props,
        childModalType: '',
      },
    }
    return state
  },

  showModalOfCloverCellPolarizationPremiumContactTMYTEK: (state, action) => {
    state.normal = {
      type: 'CLOVER_CELL_POLARIZATION',
      props: {
        ...current(state).normal.props,
        status: 'premiumContactTMYTEK',
      },
    }
    return state
  },
}
export default beamformers
