import React from 'react'

import FileImport from 'src/containers/Modal/Normal/FileImport'
import FileExport from 'src/containers/Modal/Normal/FileExport'

import DownloadMid from 'src/containers/Modal/Normal/DownloadMid'

import Operating from 'src/containers/Modal/Normal/Operating'

import TMXLABFullAnimationModal from 'src/containers/Modal/Normal/Lottie/TMXLABFullAnimationModal'
import TMXLABShortAnimationModal from 'src/containers/Modal/Normal/Lottie/TMXLABShortAnimationModal'
import TMXLABLoginWelcome from 'src/containers/Modal/Normal/Lottie/TMXLABLoginWelcome'

import Home from 'src/containers/Modal/Normal/home'

import FreqConverter from 'src/containers/Modal/Normal/freqConverter'

import CustomAntenna from 'src/containers/Modal/Normal/beamformers/bbox/DeviceController/CustomAntenna'
import BeamConfigEditor from 'src/containers/Modal/Normal/beamformers/bbox/BeamConfigEditor'
import SaveToBeamConfig from 'src/containers/Modal/Normal/beamformers/bbox/DeviceController/SaveToBeamConfig'

import PowerDetector from 'src/containers/Modal/Normal/PowerDetector'
import CloverCellPolarization from 'src/containers/Modal/Normal/beamformers/cloverCell/Polarization'

import ChangeProfilePhoto from 'src/containers/Modal/Normal/member/ChangeProfilePhoto'
import ChangeName from 'src/containers/Modal/Normal/member/ChangeName'
import ChangePassword from 'src/containers/Modal/Normal/member/ChangePassword'

import GetQuote from 'src/containers/Modal/Normal/GetQuote'
import WelcomeDemo from './WelcomeDemo'

const NormalModal = props => {
  const { type: modalType, props: modalProps } = props
  const { status: modalStatus } = modalProps

  if (modalType === 'WELCOME_DEMO') return <WelcomeDemo {...modalProps} />

  if (modalType === 'FILE_IMPORT') return <FileImport {...modalProps} />
  if (modalType === 'FILE_EXPORT') return <FileExport {...modalProps} />

  // middleware
  if (modalType === 'MIDDLEWARE') return <DownloadMid {...modalProps} />

  // scan
  if (modalType === 'TMYTEK_FULL_ANIMATION')
    return <TMXLABFullAnimationModal {...modalProps} />
  if (modalType === 'TMYTEK_SHORT_ANIMATION')
    return <TMXLABShortAnimationModal {...modalProps} />
  if (modalType === 'TMYTEK_LOGIN_WELCOME')
    return <TMXLABLoginWelcome {...modalProps} />

  // home
  if (modalType === 'HOME') return <Home {...modalProps} />

  // operating
  if (modalType === 'OPERATING') return <Operating {...modalProps} />

  // freqConverter
  if (modalType === 'FREQ_CONVERTER') return <FreqConverter {...modalProps} />

  // beamformers - bbox
  if (modalType === 'BBOX_CUSTOM_ANTENNA')
    return <CustomAntenna {...modalProps} />

  if (modalType === 'BEAM_CONFIG_EDITOR')
    return <BeamConfigEditor {...modalProps} />

  if (modalType === 'SAVE_TO_BEAM_CONFIG')
    return <SaveToBeamConfig {...modalProps} />

  // beamformers - clover cell
  if (modalType === 'CLOVER_CELL_POLARIZATION')
    return <CloverCellPolarization {...modalProps} />

  // powerDetector
  if (modalType === 'POWER_DETECTOR') return <PowerDetector {...modalProps} />

  // member
  if (modalType === 'MEMBER' && modalStatus === 'changeProfilePhoto')
    return <ChangeProfilePhoto {...modalProps} />

  if (modalType === 'MEMBER' && modalStatus === 'changeName')
    return <ChangeName {...modalProps} />

  if (modalType === 'MEMBER' && modalStatus === 'changePassword')
    return <ChangePassword {...modalProps} />

  // get quote
  if (modalType === 'GET_QUOTE') return <GetQuote {...modalProps} />
}

NormalModal.propTypes = {}

export default NormalModal
