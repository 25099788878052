import { createSlice, current } from '@reduxjs/toolkit'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------        All         -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
// 全部 cloverCellEvb 共用控項，也就是動一台 cloverCellEvb, 全部 cloverCellEvb 都受影響
const cloverCellEvbUIControlForAllInitData = {
  dontShowAgainForSteeringMask: { channelMode: false, instruction: false },

  // customAntennaListSortRule
  // 客製化天線清單的排序，動一次全 cloverCellEvb 都會共用
  // 且這個值不能隨著 modal 消失而被改變
  // 'Latest to oldest' || 'Oldest to latest'
  customAntennaListSortRule: 'Latest to oldest',
}
export const cloverCellEvbUIControlForAllSlice = createSlice({
  name: 'cloverCellEvbUIControlForAll',
  initialState: cloverCellEvbUIControlForAllInitData,
  reducers: {
    setMaskDontShowAgain(state, action) {
      const { key, value } = action.payload
      return {
        ...current(state),
        dontShowAgainForSteeringMask: {
          ...current(state).dontShowAgainForSteeringMask,
          [key]: value,
        },
      }
    },
    setCustomAntennaSortRule(state, action) {
      const { value } = action.payload
      return {
        ...current(state),
        customAntennaListSortRule: value,
      }
    },
  },
})

export const { setMaskDontShowAgain, setCustomAntennaSortRule } =
  cloverCellEvbUIControlForAllSlice.actions

export const cloverCellEvbUIControlForAll =
  cloverCellEvbUIControlForAllSlice.reducer

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------      Single        -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const itemData = {
  deviceControl: {
    showFirst3DInstructionMask: true,
    showManual3DInstructionMask: false,
    showLoadingMask: { common: false, steering: false, channel: false },
    deviceDirection: 'left', // top , left
    beam3DCameraArgs: {
      rotation: null,
      position: null,
    },
  },
  current: {
    isOperatingStatusReading: false,
  },
}

export const cloverCellEvbUIControlForSingleSlice = createSlice({
  name: 'cloverCellEvbUIControlForSingle',
  initialState: {},
  reducers: {
    addUiControl(state, action) {
      const { sn } = action.payload
      return { ...current(state), [sn]: itemData }
    },

    // device control
    setShowFirst3DInstructionMask(state, action) {
      const { sn, value } = action.payload
      state[sn].deviceControl.showFirst3DInstructionMask = value
      return state
    },
    setShowManual3DInstructionMask(state, action) {
      const { sn, value } = action.payload
      state[sn].deviceControl.showManual3DInstructionMask = value
      return state
    },

    change3DDirection(state, action) {
      const { sn, value } = action.payload
      state[sn].deviceControl.deviceDirection = value
      return state
    },

    setCameraArgs(state, action) {
      const { sn, rotation, position } = action.payload
      state[sn].deviceControl.beam3DCameraArgs.rotation = rotation
      state[sn].deviceControl.beam3DCameraArgs.position = position
      return state
    },

    setShowLoadingMask(state, action) {
      const { sn, value } = action.payload
      state[sn].deviceControl.showLoadingMask = value
      return state
    },

    setIsOperatingStatusReading(state, action) {
      const { sn, value } = action.payload
      state[sn].current.isOperatingStatusReading = value
      return state
    },
  },
})

export const {
  addUiControl,

  // steering
  changeControlContent,
  setShowFirst3DInstructionMask,
  setShowManual3DInstructionMask,
  change3DDirection,
  setCameraArgs,
  setShowLoadingMask,

  setIsOperatingStatusReading,
} = cloverCellEvbUIControlForSingleSlice.actions

export const cloverCellEvbUIControlForSingle =
  cloverCellEvbUIControlForSingleSlice.reducer
