import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import CommonButton from 'src/components/Button/CommonButton'

import { getDeviceInfo } from 'src/funcs/getDeviceInfo'

import operationalFailureImg from 'src/assets/img/operational_failure.png'

import { home_InitFailedRetryClick_watcher } from 'src/redux/actions/facility/home'

const InitFailed = ({ sn, deviceType }) => {
  const dispatch = useDispatch()

  const requestingList = useSelector(
    state => state.homeUiControl.lstInitFailedRetryRequesting
  )

  const isRequesting = requestingList?.includes(sn)

  const officialNameFirst = deviceType =>
    getDeviceInfo[deviceType].officialName.first

  const handleRetryClick = () =>
    dispatch(home_InitFailedRetryClick_watcher({ sn }))

  return (
    <div className='flex flex-col items-center'>
      <img
        src={operationalFailureImg}
        alt='operational Failure Img'
        className='mb-8'
      />
      <h3 className='font-bold text-white'>
        Failed to initialize {officialNameFirst(deviceType)}
      </h3>
      <p className='text-center text-white'>
        Please check the network connection status and try again. If the problem
        persists, please contact TMYTEK.
      </p>

      <CommonButton
        type='general'
        size='lg'
        className='w-full mt-16'
        loading={isRequesting}
        onClick={handleRetryClick}>
        Retry
      </CommonButton>
    </div>
  )
}

export default InitFailed
