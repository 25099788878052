import { nanoid } from 'nanoid'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------    Device info     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* 用來判斷該 deviceType 在 Home info 顯示什麼屬性
//* 屬性表：'ip', 'mac', 'staticIP', 'hardware', 'caliTable', 'firmware'
export const infoContentList = {
  4: ['ip', 'mac', 'staticIP', 'hardware', 'firmware'], // ud 00e
  7: ['ip', 'mac', 'staticIP', 'caliTable', 'hardware', 'firmware'], // bbox lite 28
  8: ['ip', 'mac', 'staticIP', 'caliTable', 'hardware', 'firmware'], // bbox lite 39
  9: ['ip', 'mac', 'staticIP', 'caliTable', 'hardware', 'firmware'], // bbox one 28
  10: ['ip', 'mac', 'staticIP', 'caliTable', 'hardware', 'firmware'], // bbox one 39
  12: ['ip', 'mac', 'staticIP', 'hardware', 'firmware'], // bboard 28
  13: ['ip', 'mac', 'staticIP', 'hardware', 'firmware'], // bboard 39
  14: ['ip', 'mac', 'staticIP', 'hardware', 'firmware'], // ud 00f
  15: ['ip', 'mac', 'staticIP', 'hardware', 'firmware'], // ud 5g
  17: ['ip', 'mac', 'staticIP', 'hardware', 'firmware'], // bboard 26
  18: ['ip', 'mac', 'staticIP', 'caliTable', 'hardware', 'firmware'], // bbox lite 26
  19: ['ip', 'mac', 'staticIP', 'caliTable', 'hardware', 'firmware'], // bbox one 26
  20: ['ip', 'mac', 'staticIP', 'caliTable', 'hardware', 'firmware'], // bbox lite ni
  21: ['ip', 'mac', 'staticIP', 'caliTable', 'hardware', 'firmware'], // bbox one ni
  22: ['hardware', 'firmware'], // power detector
  23: ['hardware', 'firmware'], // ud module usb
  24: ['hardware', 'firmware'], // ud module ethernet
  25: ['ip', 'mac', 'staticIP', 'caliTable', 'hardware', 'firmware'], // clover cell - evb
  28: ['ip', 'mac', 'staticIP', 'caliTable', 'hardware', 'firmware'], // ris
  255: ['ip', 'mac', 'staticIP', 'caliTable', 'hardware', 'firmware'], // clover cell - 1024
}

export const supportConnectionInfo = [
  4, 7, 8, 9, 10, 12, 13, 14, 15, 17, 18, 19, 20, 21, 25, 26, 28,
]
export const supportVersionInfo = [
  4, 7, 8, 9, 10, 12, 13, 14, 15, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 28,
]

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------        BBox        -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
export const getCommonGainMin = {
  tx: {
    7: 0,
    8: 0,
    9: -6.5,
    10: -6.5,
    12: 0,
    13: 0,
    17: 0,
    18: 0,
    19: -6.5,
    20: 0,
    21: -6.5,
  },
  rx: {
    7: -6.5,
    8: -6.5,
    9: -17.5,
    10: -17.5,
    12: -6.5,
    13: -6.5,
    17: -6.5,
    18: -6.5,
    19: -17.5,
    20: -6.5,
    21: -17.5,
  },
}
export const getCommonGainMax = {
  tx: {
    7: 15,
    8: 15,
    9: 7.5,
    10: 7.5,
    12: 15,
    13: 15,
    17: 15,
    18: 15,
    19: 7.5,
    20: 15,
    21: 7.5,
  },
  rx: {
    7: 10.5,
    8: 10.5,
    9: -0.5,
    10: -0.5,
    12: 10.5,
    13: 10.5,
    17: 10.5,
    18: 10.5,
    19: -0.5,
    20: 10.5,
    21: -0.5,
  },
}
export const getBeamGainMin = {
  tx: {
    7: 0,
    8: 0,
    9: -6.5,
    10: -6.5,
    12: 0,
    13: 0,
    17: 0,
    18: 0,
    19: -6.5,
    20: 0,
    21: -6.5,
  },
  rx: {
    7: -6.5,
    8: -6.5,
    9: -17.5,
    10: -17.5,
    12: -6.5,
    13: -6.5,
    17: -6.5,
    18: -6.5,
    19: -17.5,
    20: -6.5,
    21: -17.5,
  },
}
export const getBeamGainMax = {
  tx: {
    7: 18.5,
    8: 18.5,
    9: 11,
    10: 11,
    12: 18.5,
    13: 18.5,
    17: 18.5,
    18: 18.5,
    19: 11,
    20: 18.5,
    21: 11,
  },
  rx: {
    7: 14,
    8: 14,
    9: 3,
    10: 3,
    12: 14,
    13: 14,
    17: 14,
    18: 14,
    19: 3,
    20: 14,
    21: 3,
  },
}

export const boardCount = {
  7: 1,
  8: 1,
  9: 4,
  10: 4,
  12: 1,
  13: 1,
  17: 1,
  18: 1,
  19: 4,
  20: 1,
  21: 4,
}

export const chCount = {
  7: 4,
  8: 4,
  9: 4,
  10: 4,
  12: 4,
  13: 4,
  17: 4,
  18: 4,
  19: 4,
  20: 4,
  21: 4,
}

export const elementGainMin = 0

export const elementGainMax = 3.5

export const getLstAntennaData = deviceType => {
  let type = deviceType === 7 || deviceType === 8 ? 'LITE' : 'ONE'

  let result = [
    {
      id: nanoid(),
      name: '', // str （官方的天線名稱一定會有 4x4 , 8x8 ，直接判斷name）
      type: 'official', // official or customized
      deviceType: '',
      spacingX: 0, // int
      spacingY: 0, // int
      thetaMax: 0, // int
      rxOffset: [], // List[float]
      txOffset: [], // List[float]
    },
    {
      id: nanoid(),
      name: `Demo_Antenna_${type}_4x4`,
      type: 'official', // official or customized
      deviceType: '',
      spacingX: 0, // int
      spacingY: 0, // int
      thetaMax: 40, // int
      rxOffset: new Array(16).fill(0), // List[float]
      txOffset: new Array(16).fill(0), // List[float]
    },
  ]

  return result
}

export const getDefaultCurrentFreq = {
  7: 28,
  8: 39,
  9: 28,
  10: 39,
  12: 28,
  13: 39,
  17: 26,
  18: 26,
  19: 26,
  20: 28,
  21: 28,
  25: 28,
  28: 28,
  255: 24,
}

export const getLstFreqOptions = {
  7: [26.5, 27, 27.5, 28, 28.5, 29, 29.5], // lite 28
  8: [37, 37.5, 38, 38.5, 39, 39.5, 40], // lite 39
  9: [26.5, 27, 27.5, 28, 28.5, 29, 29.5], // one 28
  10: [37, 37.5, 38, 38.5, 39, 39.5, 40], // one 39
  18: [24.5, 25, 25.5, 26, 26.5, 27, 27.5], // lite 26
  19: [24.5, 25, 25.5, 26, 26.5, 27, 27.5], // one 26
  20: [26.5, 27, 27.5, 28, 28.5, 29, 29.5], // lite 28 ni
  21: [26.5, 27, 27.5, 28, 28.5, 29, 29.5], // one 28 ni
  25: [26.5, 27, 27.5, 28, 28.5, 29, 29.5], // clover cell evb
  28: [26.5, 27, 27.5, 28, 28.5, 29, 29.5], // ris
  255: [26.5, 27, 27.5, 28, 28.5, 29, 29.5], // clover cell 1024
}

//* ------------------ ------------------- -----------------
//* ------------------ ------------------- -----------------
//* ------------------       UDModule      -----------------
//* ------------------ ------------------- -----------------
//* ------------------ ------------------- -----------------
export const udIfDeviceRange = {
  23: { min: 1000000, max: 10000000 },
  24: { min: 1000000, max: 8000000 },
}
export const udRfLoUserRange = {
  23: { min: 6000000, max: 10000000 },
  24: { min: 6000000, max: 10000000 },
}
