import { all, fork, take, throttle } from 'redux-saga/effects'

// action

import {
  cloverCell1024_initDevice_watcher,
  cloverCell1024_common_rfModeChange_watcher,
  cloverCell1024_common_externalControlChange_watcher,
  cloverCell1024_common_freqSelect_watcher,
  cloverCell1024_common_sgFreqChange_watcher,
  cloverCell1024_channel_allPowerSwitch_watcher,
  cloverCell1024_steering_thetaPhiChange_watcher,
  cloverCell1024_steering_gainChange_watcher,
  cloverCell1024_steering_3DCameraChange_watcher,
  cloverCell1024_steering_channelModeMaskClose_watcher,
} from 'src/redux/actions/beamformers/cloverCellArray1024Action'

// handlers
import { init as handleInit } from 'src/redux/sagas/handlers/event/beamformers/general/init'
import {
  commonRfModeSwitch as handleCommonRfModeSwitch,
  commonFreqSelect as handleCommonFreqSelect,
} from 'src/redux/sagas/handlers/event/beamformers/general/common'
import {
  commonExternalControlChange as handleCommonExternalControlChange,
  commonSignalGeneratorChange as handleCommonSignalGeneratorChange,
} from 'src/redux/sagas/handlers/event/beamformers/cloverCell/array1024/common'

import { channelAllPower as handleChannelAllPower } from 'src/redux/sagas/handlers/event/beamformers/cloverCell/array1024/channel'

import {
  steeringThetaPhiChange as handleSteeringThetaPhiChange,
  steeringGainChange as handleSteeringGainChange,
  steering3DCameraChange as handleSteering3DCameraChange,
  steeringChannelModeMaskClose as handleSteeringChannelModeMaskClose,
} from 'src/redux/sagas/handlers/event/beamformers/cloverCell/array1024/steering'

import { devLog } from 'src/funcs/tools'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------       General      -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
function* initCloverEvb() {
  while (true) {
    const action = yield take(cloverCell1024_initDevice_watcher.type)
    devLog('[watcher] initCloverEvb', action.payload)
    yield fork(handleInit, action.payload)
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------       Common       -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
function* commonRfModeChange() {
  while (true) {
    const action = yield take(cloverCell1024_common_rfModeChange_watcher.type)
    devLog('[watcher] commonRfModeChange', action.payload)
    yield fork(handleCommonRfModeSwitch, action.payload)
  }
}
//* ----------------- ------------------ -----------------
function* commonExternalControlChange() {
  while (true) {
    const action = yield take(
      cloverCell1024_common_externalControlChange_watcher.type
    )
    devLog('[watcher] commonExternalControlChange', action.payload)
    yield fork(handleCommonExternalControlChange, action.payload)
  }
}
function* commonFreqSelect() {
  while (true) {
    const action = yield take(cloverCell1024_common_freqSelect_watcher.type)
    devLog('[watcher] commonFreqSelect', action.payload)
    yield fork(handleCommonFreqSelect, action.payload)
  }
}
function* commonSignalGeneratorFreqChange() {
  while (true) {
    const action = yield take(cloverCell1024_common_sgFreqChange_watcher.type)
    devLog('[watcher] commonSignalGeneratorFreqChange', action.payload)
    yield fork(handleCommonSignalGeneratorChange, action.payload)
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------       Channel      -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
function* channelAllPower() {
  while (true) {
    const action = yield take(
      cloverCell1024_channel_allPowerSwitch_watcher.type
    )
    devLog('[watcher] channelAllPower', action.payload)
    yield fork(handleChannelAllPower, action.payload)
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------    Beam Steering   -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
function* steeringChannelModeMaskClose() {
  while (true) {
    const action = yield take(
      cloverCell1024_steering_channelModeMaskClose_watcher.type
    )
    devLog('[watcher] steeringRemoveMaskForChannelChanged', action.payload)
    yield fork(handleSteeringChannelModeMaskClose, action.payload)
  }
}

function* steeringThetaPhiChange() {
  while (true) {
    const action = yield take(
      cloverCell1024_steering_thetaPhiChange_watcher.type
    )
    devLog('[watcher] steeringThetaPhiChange', action.payload)
    yield fork(handleSteeringThetaPhiChange, action.payload)
  }
}

function* steeringGainChange() {
  while (true) {
    const action = yield take(cloverCell1024_steering_gainChange_watcher.type)
    devLog('[watcher] steeringGainChange', action.payload)
    yield fork(handleSteeringGainChange, action.payload)
  }
}

function* steering3DCameraChange() {
  yield throttle(
    1000,
    cloverCell1024_steering_3DCameraChange_watcher,
    handleSteering3DCameraChange
  )
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------       Current      -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------

export default function* bboxWatchers() {
  yield all([
    initCloverEvb(),

    commonRfModeChange(),
    commonExternalControlChange(),
    commonFreqSelect(),
    commonSignalGeneratorFreqChange(),

    channelAllPower(),

    steeringChannelModeMaskClose(),
    steeringThetaPhiChange(),
    steeringGainChange(),
    steering3DCameraChange(),
  ])
}
