import { call, put, select, delay } from 'redux-saga/effects'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'
import { udboxUIControlForSingleAction as uiControlAction } from 'src/redux/slices/uiControl/freqConverter/udbox'

import { modalActions } from 'src/redux/slices/modal'

import { getCurrentData } from 'src/redux/sagas/selector/deviceData'

import { devWarLog } from 'src/funcs/tools'

export function* deviceSetting(response) {
  try {
    const [sn, { label, value, ledIndicators }] = Object.entries(
      response?.data
    )[0]

    // response success 後才改值，並更新來自 response 的 ledIndicators
    let currentData = yield call(getCurrentData, sn)

    currentData.settings.device[label] = value
    currentData.current.ledIndicators = ledIndicators

    // [spec]
    // 如果切到 external, 要把 output 100m 關掉
    // (因為 都吃外部訊號了，不可能還當訊號源 output 給別人)
    if (label === 'SOURCE_100M' && value === 1) {
      currentData.settings.device['OUT_100M'] = 0
      yield put(uiControlAction.setUD5gRefSourceIsSuccess({ sn }))
    }

    // [spec]
    // 如果回 internal
    // 讓 output 100MHz 的按鈕直接跟燈號狀態一致
    // (因為這燈號會 keep 在先前 internal 的狀態)
    if (label === 'SOURCE_100M' && value === 0)
      currentData.settings.device['OUT_100M'] = ledIndicators.OUT_100M

    yield put(setSingleDeviceData({ sn, data: currentData }))

    // 這邊做 modal 相關處理
    if (label === 'SOURCE_100M') {
      // 切 external success
      if (+value === 1) {
        // 會有 success 狀態打勾狀態要顯示所以 delay 1000 再關 Modal
        yield delay(1000)

        yield put(uiControlAction.clearUD5gRefSourceStatus({ sn }))
        yield put(modalActions.clearNormalModal())
      }

      // 切 internal success
      if (+value === 0) {
        const normalModalState = yield select(
          state => state.facilityModal.normal
        )

        // 唯一要關 normal modal 的情境是 「切 external 失敗後按 cancel 後的 call internal 成功」
        if (normalModalState.props.status === 'udboxToExternalError') {
          yield put(uiControlAction.clearUD5gRefSourceStatus({ sn }))
          yield put(modalActions.clearNormalModal())
        }
      }
    }
  } catch (error) {
    devWarLog('[res-handler] deviceSetting error:', error)
  }
}
