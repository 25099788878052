import generalApi from 'src/redux/sagas/services/socketAPI/beamformers/general'
import bboxApi from 'src/redux/sagas/services/socketAPI/beamformers/bbox'
import bboardApi from 'src/redux/sagas/services/socketAPI/beamformers/bboard'
import cloverCellEvbApi from 'src/redux/sagas/services/socketAPI/beamformers/cloverCellEvb'
import cloverCellArray1024Api from 'src/redux/sagas/services/socketAPI/beamformers/cloverCellArray1024'
import risApi from 'src/redux/sagas/services/socketAPI/beamformers/ris'

import {
  connect as handleConnect,
  disconnect as handleDisconnect,
  // connectError as handleConnectError,
} from 'src/redux/sagas/handlers/event/socket/beamformers'

const beamformersApi = {
  ...generalApi,
  ...bboxApi,
  ...bboardApi,
  ...cloverCellEvbApi,
  ...cloverCellEvbApi,
  ...cloverCellArray1024Api,
  ...risApi,

  // socket listener
  SOCKET_CONNECT: {
    on: {
      type: 'SOCKET_EVENT',
      eventName: 'connect',
      handler: handleConnect,
    },
  },
  SOCKET_DISCONNECT: {
    on: {
      type: 'SOCKET_EVENT',
      eventName: 'disconnect',
      handler: handleDisconnect,
    },
  },
  // SOCKET_CONNECT_ERROR: {
  //   on: {
  //     type: 'SOCKET_EVENT',
  //     eventName: 'connect_error',
  //     handler: handleConnectError,
  //   },
  // },
}

export default beamformersApi
