import React, { useState } from 'react'

import IconButton from 'src/components/Button/IconButton'
import OverlayDeviceInventory from 'src/containers/Header/OverlayDeviceInventory'
import Item from 'src/containers/operating/DeviceInventory/Item'

import { getOfficialFullName } from 'src/funcs/getDeviceInfo'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'
import useGetScreenSize from 'src/hooks/useGetScreenSize'

import { BiMenu } from 'react-icons/bi'

const CurrentDevice = props => {
  const { current } = useGetCurrentDeviceData()
  const { isSM, isMD } = useGetScreenSize()

  const [showOverLay, setShowOverlay] = useState(false)

  const deviceName = deviceType => deviceType && getOfficialFullName(deviceType)

  const supportCurrentDeviceSize = isSM || isMD

  return (
    current.sn &&
    supportCurrentDeviceSize && (
      <div
        className={containerClass}
        onClick={() => setShowOverlay(prev => !prev)}>
        <div className={widthLimit}>
          <div className='relative w-full h-full flex justify-center items-center'>
            {/* mask 是為了讓 Item 不要吃到 hover 效果  */}
            <div className={itemMask} />

            <Item
              name={deviceName(current.deviceType)}
              sn={current.sn}
              deviceType={current.deviceType}
              layout='row'
            />

            <IconButton
              size='lg'
              type='standard'
              className='absolute z-20 right-3 md:right-7'>
              <BiMenu />
            </IconButton>
          </div>

          {showOverLay && <OverlayDeviceInventory />}
        </div>
      </div>
    )
  )
}

CurrentDevice.propTypes = {}

export default CurrentDevice

const containerClass = `
                        h-[72px] w-full 
                        bg-light-4/10 backdrop-blur-md
                        duration-500 
                        `

const widthLimit = `relative
                        w-full h-full max-w-[1440px] 
                        mx-auto 
                        flex items-center justify-center 
                        cursor-pointer
                        `

const itemMask = `absolute inset-0 z-10`
