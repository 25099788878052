import React, { useState } from 'react'
import * as R from 'ramda'
import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

const width = 8
const height = 8
const gap = 1

const _getX = (i, a) => Math.floor(i) * (width + gap)
const _getY = row_i => Math.floor(row_i * (height + gap))

const Channel = props => {
  const { current } = useGetCurrentDeviceData()

  const thetaI = current.data.deviceControl.steering?.incident?.[0]?.theta
  const thetaR = current.data.deviceControl.steering?.reflector?.[0]?.theta
  const phiI = current.data.deviceControl.steering?.incident?.[0]?.phi
  const phiR = current.data.deviceControl.steering?.reflector?.[0]?.phi
  const data = current.data.deviceControl.channel

  return (
    <div>
      <h3 className='text-sm text-light-1 mb-4'>Radiation Pattern</h3>

      <div className='functionBlockBackground p-4 rounded-md flex flex-col items-center pb-14'>
        <h3 className='text-base text-center text-light-1 font-bold mb-4'>
          INC. θ {thetaI}°, φ {phiI}° | REF. θ {thetaR}°, φ {phiR}°
        </h3>

        <svg className='w-[287px] h-[287px]'>
          {data.map((row_e, row_i) =>
            row_e.map((e, i, a) => (
              <rect
                key={`ris channel rect ${i}`}
                x={_getX(i, a)}
                y={_getY(row_i)}
                width='8'
                height='8'
                fill={
                  +e ? 'hsla(45, 100%, 81%, 0.7)' : 'hsla(45, 100%, 81%, 0.1)'
                }
                // onClick={() => handleClick(i)}
              />
            ))
          )}
        </svg>
      </div>
    </div>
  )
}

Channel.propTypes = {}

export default Channel
