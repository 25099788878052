import React from 'react'

import { getDeviceInfo } from 'src/funcs/getDeviceInfo'

const Avatar = props => {
  const { sn, deviceType } = props

  const avatar = getDeviceInfo[deviceType].avatar(sn)

  const randomPosition = () => {
    let randomNumber = Math.random().toFixed(2) * 100
    if (randomNumber <= 25) return 'top'
    if (randomNumber <= 50) return 'right'
    if (randomNumber <= 75) return 'bottom'
    return 'left'
  }
  const degList = {
    top: [13, 130],
    right: [117, 180],
    bottom: [193, 140],
    left: [-117, 200],
  }
  let degPosition = randomPosition()

  const bg2Style = {
    background: `linear-gradient(${degList[degPosition][0]}deg, transparent ${degList[degPosition][1]}px, rgba(68, 68, 68, 0.25) 0) left `,
  }

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div className={container}>
      <div className='absolute inset-0' style={bg2Style} />
      {avatar}
    </div>
  )
}
export default Avatar

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------     Static CSS     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const container =
  'relative w-full h-[200px] shrink-0 bg-[#444444]/30 flex items-center justify-center rounded-t-md'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Type  validate   -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
Avatar.propTypes = {}
