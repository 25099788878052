import { put } from 'redux-saga/effects'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'
import { addUiControl as bboxAddUIControl } from 'src/redux/slices/uiControl/beamformers/bbox'
import { addUiControl as bboardAddUIControl } from 'src/redux/slices/uiControl/beamformers/bboard'
import { addUiControl as cloverCellEvbAddUIControl } from 'src/redux/slices/uiControl/beamformers/cloverCellEvb'
import { addUiControl as risAddUIControl } from 'src/redux/slices/uiControl/beamformers/ris'

import { getDeviceInfo } from 'src/funcs/getDeviceInfo'

import { devWarLog } from 'src/funcs/tools'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------        Init        -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
export function* init(response) {
  try {
    const [sn, { deviceData }] = Object.entries(response?.data)[0]

    yield put(setSingleDeviceData({ sn, data: deviceData }))

    const resDeviceType = deviceData.info.deviceType
    const { first: deviceName } = getDeviceInfo[resDeviceType].devName

    const addUiControlAction = {
      bbox: bboxAddUIControl,
      bboard: bboardAddUIControl,
      cloverCellEvb: cloverCellEvbAddUIControl,
      cloverCellArray1024: cloverCellEvbAddUIControl,
      ris: risAddUIControl,
    }

    yield put(addUiControlAction[deviceName]({ sn }))
  } catch (error) {
    devWarLog('[res-handler] init error:', error)
  }
}
