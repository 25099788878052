import React, { useState } from 'react'
import PropTypes from 'prop-types'

import InputGroupAppendDialog from 'src/components/Mix/InputGroupAppendDialog'

import SwitchGroup from 'src/components/Switch/SwitchGroup'
import InputGroup from 'src/components/Input/InputGroup'

import { getDeviceInfo } from 'src/funcs/getDeviceInfo'

import {
  thetaIcon,
  thetaMin,
  thetaStep,
  thetaUnit,
  thetaDecimalScale,
  phiIcon,
  phiMin,
  phiMax,
  phiStep,
  phiUnit,
  phiDecimalScale,
  gainIcon,
  gainStep,
  gainUnit,
  gainDecimalScale,
  getThetaPhiPhaseWarningText,
  getGainWarningText,
} from 'src/constants/beamformers'

const InputControl = ({
  deviceType,
  theta,
  phi,
  beamCurrentGain,
  thetaMax,
  beamGainMin,
  beamGainMax,
  handleInputChange,
}) => {
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       State        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------

  const isBBoxLite = getDeviceInfo[deviceType].devName.second === 'lite'

  // ---- warning status
  const [thetaWarning, setThetaWarning] = useState('')
  const [phiWarning, setPhiWarning] = useState('')
  const [gainWarning, setGainWarning] = useState('')
  const [mobileGainWarning, setMobileGainWarning] = useState('')

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Props       ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  // ======================
  // warning text
  // ======================
  const thetaWarningText = getThetaPhiPhaseWarningText({
    min: thetaMin,
    max: thetaMax,
    step: thetaStep,
  })

  const phiWarningText = getThetaPhiPhaseWarningText({
    min: phiMin,
    max: phiMax,
    step: phiStep,
  })

  const gainWarningText = getGainWarningText({
    min: beamGainMin,
    max: beamGainMax,
    step: gainStep,
  })

  // ======================
  // Input props
  // ======================
  const thetaInputProps = {
    className: 'w-full ',
    size: 'lg',
    icon: thetaIcon,
    unit: thetaUnit,
    step: thetaStep,
    loop: false,
    validMin: thetaMin,
    validMax: thetaMax,
    decimalScale: thetaDecimalScale,
    value: String(theta),
  }

  const phiInputProps = {
    className: 'w-full ',
    size: 'lg',
    icon: phiIcon,
    unit: phiUnit,
    step: phiStep,
    loop: true,
    validMin: phiMin,
    validMax: phiMax,
    decimalScale: phiDecimalScale,
    value: String(phi),
  }

  const gainInputProps = {
    className: 'w-full',
    size: 'lg',
    icon: gainIcon,
    unit: gainUnit,
    step: gainStep,
    loop: false,
    validMin: beamGainMin,
    validMax: beamGainMax,
    decimalScale: gainDecimalScale,
    value: String(beamCurrentGain),
  }

  // ======================
  // OverwriteElement
  // ======================
  const thetaDialogOverwriteElement = (
    <div className={itemBox}>
      <InputGroup warning={true} disabled={true} {...thetaInputProps} />
      <span className={rangeText + ' text-yellow'}>Max {thetaMax}</span>
    </div>
  )
  const phiDialogOverwriteElement = (
    <div className={itemBox}>
      <InputGroup warning={true} disabled={true} {...phiInputProps} />
      <span className={rangeText + ' text-yellow'}>Max {phiMax}</span>
    </div>
  )
  const gainDialogOverwriteElement = (
    <div className={itemBox}>
      <InputGroup warning={true} disabled={true} {...gainInputProps} />
      <span className={rangeText + ' text-yellow'}>
        Range {beamGainMin} ~ {beamGainMax}
      </span>
    </div>
  )

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div className={container}>
      <div className='w-full flex justify-start gap-x-4 xl:gap-x-6 '>
        <div className={itemBox}>
          <InputGroupAppendDialog
            {...thetaInputProps}
            // dialog params
            dialogText={thetaWarningText[thetaWarning]}
            placement='top'
            overwriteOffset={{ top: 0, left: 0 }}
            overwriteElements={thetaDialogOverwriteElement}
            // event params
            warning={thetaWarning}
            setWarning={result => setThetaWarning(result)}
            changeCallback={({ value, isValid }) =>
              handleInputChange({ label: 'theta', value, isValid })
            }
          />
          <span className={rangeText}>
            {!thetaWarning && `Max ${thetaMax}`}
          </span>
        </div>

        <div className={itemBox}>
          {isBBoxLite ? (
            <SwitchGroup
              className='w-full '
              size='lg'
              value={+phi}
              icon={phiIcon}
              text1={0}
              text2={180}
              unit='˚'
              onClick={() =>
                handleInputChange({
                  label: 'phi',
                  value: +phi === 0 ? 180 : 0,
                  isValid: true,
                })
              }
            />
          ) : (
            <>
              <InputGroupAppendDialog
                {...phiInputProps}
                // dialog params
                dialogText={phiWarningText[phiWarning]}
                placement='top'
                overwriteOffset={{ top: 0, left: 0 }}
                overwriteElements={phiDialogOverwriteElement}
                // event params
                warning={phiWarning}
                setWarning={result => setPhiWarning(result)}
                changeCallback={({ value, isValid }) =>
                  handleInputChange({ label: 'phi', value, isValid })
                }
              />
              <span className={rangeText}>
                {!phiWarning && `Max ${phiMax}`}
              </span>
            </>
          )}
        </div>

        <div className={itemBox + ' hidden xl:hidden md:block '}>
          <InputGroupAppendDialog
            {...gainInputProps}
            // dialog params
            dialogText={gainWarningText[gainWarning]}
            placement='top'
            overwriteOffset={{ top: 0, left: 0 }}
            overwriteElements={gainDialogOverwriteElement}
            // event params
            warning={gainWarning}
            setWarning={result => setGainWarning(result)}
            changeCallback={({ value, isValid }) =>
              handleInputChange({ label: 'gain', value, isValid })
            }
          />
          <span className={rangeText}>
            {!gainWarning && `Range ${beamGainMin} ~ ${beamGainMax}`}
          </span>
        </div>
      </div>

      {/* 手機版專用 gain */}
      <div className={itemBox + ' block xl:block md:hidden mt-4'}>
        <InputGroupAppendDialog
          {...gainInputProps}
          // dialog params
          dialogText={gainWarningText[mobileGainWarning]}
          placement='top'
          overwriteOffset={{ top: 0, left: 0 }}
          overwriteElements={gainDialogOverwriteElement}
          // event params
          warning={mobileGainWarning}
          setWarning={result => setMobileGainWarning(result)}
          changeCallback={({ value, isValid }) =>
            handleInputChange({ label: 'gain', value, isValid })
          }
        />
        <span className={!gainWarning ? rangeText : ''}>
          {!mobileGainWarning && `Range ${beamGainMin} ~ ${beamGainMax}`}
        </span>
      </div>
    </div>
  )
}

export default InputControl

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------     Static CSS     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const container = `[BeamSteering-InputControl-container]
                    flex 
                    xl:flex-col md:flex-row sm:flex-col
                    xl:justify-stretch md:justify-center 
                    gap-x-4 
                    `
const itemBox = `flex flex-col w-full `
const rangeText = `block pl-3 text-xs text-white/50 font-normal mt-1`

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Type  validate   -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
InputControl.propTypes = {
  theta: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  phi: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleInputChange: PropTypes.func,
}
