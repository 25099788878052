import { createSlice } from '@reduxjs/toolkit'

import low from 'src/redux/slices/modal/low'
import normal from 'src/redux/slices/modal/normal'
import high from 'src/redux/slices/modal/high'
import emergency from 'src/redux/slices/modal/emergency'

export const facilityModalSlice = createSlice({
  name: 'facilityModal',
  initialState: {
    emergency: { type: '', props: {} },
    high: { type: '', props: {} },
    normal: { type: '', props: {} },
    low: { type: '', props: {} },
  },
  reducers: {
    clearEmergencyModal(state, action) {
      state.emergency = { type: '', props: {} }
      return state
    },
    clearHighModal(state, action) {
      state.high = { type: '', props: {} }
      return state
    },
    clearNormalModal(state, action) {
      state.normal = {
        type: '',
        props: { status: '' },
      }
      return state
    },
    clearLowModal(state, action) {
      state.low = { type: '', props: {} }
      return state
    },
    clearAllModal() {
      return {
        emergency: { type: '', props: {} },
        high: { type: '', props: {} },
        normal: { type: '', props: {} },
        low: { type: '', props: {} },
      }
    },

    ...emergency,
    ...high,
    ...normal,
    ...low,
  },
})

export default facilityModalSlice.reducer

export const modalActions = facilityModalSlice.actions
