import { all, fork, take, throttle } from 'redux-saga/effects'

// action

import {
  cloverCellEvb_initDevice_watcher,
  cloverCellEvb_common_rfModeChange_watcher,
  cloverCellEvb_common_freqSelect_watcher,
  cloverCellEvb_common_antennaPolarizationChange_watcher,
  cloverCellEvb_current_readMethodChange_watcher,
  cloverCellEvb_common_polarSynthesisChange_watcher,
  cloverCellEvb_current_readClick_watcher,
  cloverCellEvb_current_clearClick_watcher,
  cloverCellEvb_current_exportClick_watcher,
  cloverCellEvb_channel_allPowerSwitch_watcher,
  cloverCellEvb_channel_singlePowerSwitch_watcher,
  cloverCellEvb_channel_commonGainSliderChange_watcher,
  cloverCellEvb_channel_commonGainChange_watcher,
  // cloverCellEvb_channel_commonPhaseSliderChange_watcher,
  // cloverCellEvb_channel_commonPhaseChange_watcher,
  cloverCellEvb_channel_singleGainChange_watcher,
  cloverCellEvb_channel_singlePhaseChange_watcher,
  cloverCellEvb_steering_thetaPhiChange_watcher,
  cloverCellEvb_steering_gainChange_watcher,
  cloverCellEvb_steering_3DCameraChange_watcher,
  cloverCellEvb_steering_channelModeMaskClose_watcher,
} from 'src/redux/actions/beamformers/cloverCellEvbAction'

// handlers
import { init as handleInit } from 'src/redux/sagas/handlers/event/beamformers/general/init'
import {
  commonRfModeSwitch as handleCommonRfModeSwitch,
  commonFreqSelect as handleCommonFreqSelect,
} from 'src/redux/sagas/handlers/event/beamformers/general/common'

import {
  currentReadMethodChange as handleCurrentReadMethodChange,
  currentReadClick as handleCurrentReadClick,
  currentClearClick as handleCurrentClearClick,
  currentExportClick as handleCurrentExportClick,
} from 'src/redux/sagas/handlers/event/beamformers/cloverCell/evb/current'

import {
  commonAntennaPolarizationChange as handleCommonAntennaPolarizationChange,
  commonPolarSynthesisChange as handleCommonPolarSynthesisChange,
} from 'src/redux/sagas/handlers/event/beamformers/cloverCell/evb/common'

import {
  channelAllPower as handleChannelAllPower,
  channelSinglePower as handleChannelSinglePower,
  channelCommonGainSliderChange as handleChannelCommonGainSliderChange,
  channelCommonGainChange as handleChannelCommonGainChange,
  // channelCommonPhaseSliderChange as handleChannelCommonPhaseSliderChange,
  // channelCommonPhaseChange as handleChannelCommonPhaseChange,
  channelSingleGain as handleChannelSingleGain,
  channelSinglePhase as handleChannelSinglePhase,
} from 'src/redux/sagas/handlers/event/beamformers/cloverCell/evb/channel'

import {
  steeringThetaPhiChange as handleSteeringThetaPhiChange,
  steeringGainChange as handleSteeringGainChange,
  steering3DCameraChange as handleSteering3DCameraChange,
  steeringChannelModeMaskClose as handleSteeringChannelModeMaskClose,
} from 'src/redux/sagas/handlers/event/beamformers/cloverCell/evb/steering'

import { devLog } from 'src/funcs/tools'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------       General      -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
function* initCloverEvb() {
  while (true) {
    const action = yield take(cloverCellEvb_initDevice_watcher.type)
    // const { sn } = action.payload
    devLog('[watcher] initCloverEvb', action.payload)
    yield fork(handleInit, action.payload)
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------       Common       -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
function* commonRfModeChange() {
  while (true) {
    const action = yield take(cloverCellEvb_common_rfModeChange_watcher.type)
    // const { sn } = action.payload
    devLog('[watcher] commonRfModeChange', action.payload)
    yield fork(handleCommonRfModeSwitch, action.payload)
  }
}
function* commonFreqSelect() {
  while (true) {
    const action = yield take(cloverCellEvb_common_freqSelect_watcher.type)
    // const { sn } = action.payload
    devLog('[watcher] commonFreqSelect', action.payload)
    yield fork(handleCommonFreqSelect, action.payload)
  }
}

function* commonAntennaPolarizationChange() {
  while (true) {
    const action = yield take(
      cloverCellEvb_common_antennaPolarizationChange_watcher.type
    )
    // const { sn } = action.payload
    devLog('[watcher] commonAntennaPolarizationChange', action.payload)
    yield fork(handleCommonAntennaPolarizationChange, action.payload)
  }
}

function* commonPolarSynthesisChange() {
  while (true) {
    const action = yield take(
      cloverCellEvb_common_polarSynthesisChange_watcher.type
    )
    // const { sn } = action.payload
    devLog('[watcher] commonPolarSynthesisChange', action.payload)
    yield fork(handleCommonPolarSynthesisChange, action.payload)
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------       Channel      -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
function* channelAllPower() {
  while (true) {
    const action = yield take(cloverCellEvb_channel_allPowerSwitch_watcher.type)
    // const { sn } = action.payload
    devLog('[watcher] channelAllPower', action.payload)
    yield fork(handleChannelAllPower, action.payload)
  }
}

function* channelSinglePower() {
  while (true) {
    const action = yield take(
      cloverCellEvb_channel_singlePowerSwitch_watcher.type
    )
    // const { sn } = action.payload
    devLog('[watcher] channelSinglePower', action.payload)
    yield fork(handleChannelSinglePower, action.payload)
  }
}

function* channelCommonGainSliderChange() {
  while (true) {
    const action = yield take(
      cloverCellEvb_channel_commonGainSliderChange_watcher.type
    )
    // const { sn } = action.payload
    devLog('[watcher] channelCommonGainSliderChange', action.payload)
    yield fork(handleChannelCommonGainSliderChange, action.payload)
  }
}

function* channelCommonGainChange() {
  while (true) {
    const action = yield take(
      cloverCellEvb_channel_commonGainChange_watcher.type
    )
    // const { sn } = action.payload
    devLog('[watcher] channelCommonGainChange', action.payload)
    yield fork(handleChannelCommonGainChange, action.payload)
  }
}

// function* channelCommonPhaseSliderChange() {
//   while (true) {
//     const action = yield take(
//       cloverCellEvb_channel_commonPhaseSliderChange_watcher.type
//     )
//     // const { sn } = action.payload
//     devLog('[watcher] channelCommonPhaseSliderChange', action.payload)
//     yield fork(handleChannelCommonPhaseSliderChange, action.payload)
//   }
// }

// function* channelCommonPhaseChange() {
//   while (true) {
//     const action = yield take(
//       cloverCellEvb_channel_commonPhaseChange_watcher.type
//     )
//     // const { sn } = action.payload
//     devLog('[watcher] channelCommonPhaseChange', action.payload)
//     yield fork(handleChannelCommonPhaseChange, action.payload)
//   }
// }

function* channelSingleGain() {
  while (true) {
    const action = yield take(
      cloverCellEvb_channel_singleGainChange_watcher.type
    )
    // const { sn } = action.payload
    devLog('[watcher] channelSingleGain', action.payload)
    yield fork(handleChannelSingleGain, action.payload)
  }
}

function* channelSinglePhase() {
  while (true) {
    const action = yield take(
      cloverCellEvb_channel_singlePhaseChange_watcher.type
    )
    // const { sn } = action.payload
    devLog('[watcher] channelSinglePhase', action.payload)
    yield fork(handleChannelSinglePhase, action.payload)
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------    Beam Steering   -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
function* steeringChannelModeMaskClose() {
  while (true) {
    const action = yield take(
      cloverCellEvb_steering_channelModeMaskClose_watcher.type
    )
    devLog('[watcher] steeringRemoveMaskForChannelChanged', action.payload)
    yield fork(handleSteeringChannelModeMaskClose, action.payload)
  }
}

function* steeringThetaPhiChange() {
  while (true) {
    const action = yield take(
      cloverCellEvb_steering_thetaPhiChange_watcher.type
    )
    // const { sn } = action.payload
    devLog('[watcher] steeringThetaPhiChange', action.payload)
    yield fork(handleSteeringThetaPhiChange, action.payload)
  }
}

function* steeringGainChange() {
  while (true) {
    const action = yield take(cloverCellEvb_steering_gainChange_watcher.type)
    // const { sn } = action.payload
    devLog('[watcher] steeringGainChange', action.payload)
    yield fork(handleSteeringGainChange, action.payload)
  }
}

function* steering3DCameraChange() {
  yield throttle(
    1000,
    cloverCellEvb_steering_3DCameraChange_watcher,
    handleSteering3DCameraChange
  )
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------       Current      -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
function* currentReadMethodChange() {
  while (true) {
    const action = yield take(
      cloverCellEvb_current_readMethodChange_watcher.type
    )
    // const { sn } = action.payload
    devLog('[watcher] currentReadMethodChange', action.payload)
    yield fork(handleCurrentReadMethodChange, action.payload)
  }
}
function* currentReadClick() {
  while (true) {
    const action = yield take(cloverCellEvb_current_readClick_watcher.type)
    // const { sn } = action.payload
    devLog('[watcher] currentReadClick', action.payload)
    yield fork(handleCurrentReadClick, action.payload)
  }
}
function* currentClearClick() {
  while (true) {
    const action = yield take(cloverCellEvb_current_clearClick_watcher.type)
    // const { sn } = action.payload
    devLog('[watcher] currentClearClick', action.payload)
    yield fork(handleCurrentClearClick, action.payload)
  }
}
function* currentExportClick() {
  while (true) {
    const action = yield take(cloverCellEvb_current_exportClick_watcher.type)
    // const { sn } = action.payload
    devLog('[watcher] currentExportClick', action.payload)
    yield fork(handleCurrentExportClick, action.payload)
  }
}

export default function* bboxWatchers() {
  yield all([
    initCloverEvb(),

    commonRfModeChange(),
    commonFreqSelect(),
    commonAntennaPolarizationChange(),
    commonPolarSynthesisChange(),

    channelAllPower(),
    channelSinglePower(),
    channelCommonGainSliderChange(),
    channelCommonGainChange(),
    // channelCommonPhaseSliderChange(),
    // channelCommonPhaseChange(),
    channelSingleGain(),
    channelSinglePhase(),

    steeringChannelModeMaskClose(),
    steeringThetaPhiChange(),
    steeringGainChange(),
    steering3DCameraChange(),

    currentReadMethodChange(),
    currentReadClick(),
    currentClearClick(),
    currentExportClick(),
  ])
}
