import { spawn, all, call } from 'redux-saga/effects'

import facilityWatchers from './watchers/facilityWatchers'

import bboxWatchers from './watchers/beamformers/bboxWatchers'
import bboardWatchers from './watchers/beamformers/bboardWatchers'
import cloverCellEvbWatchers from './watchers/beamformers/cloverCellEvbWatchers'
import cloverCellArray1024Watchers from './watchers/beamformers/cloverCellArray1024Watchers'
import risWatchers from './watchers/beamformers/risWatchers'

import freqConverterGeneralWatcher from './watchers/freqConverter/generalWatchers'
import udboxWatchers from './watchers/freqConverter/udboxWatchers'
import udmoduleWatchers from './watchers/freqConverter/udmoduleWatchers'
import sgWatchers from './watchers/freqConverter/sgWatchers'

import powerDetectorWatchers from './watchers/powerDetectorWatchers'

import { devWarLog } from 'src/funcs/tools'

// 讓一切保持正常運作
// 就算出錯 saga 也會回復可用狀態
// https://juejin.cn/post/7009110590752817183
export default function* rootSaga() {
  const sagas = [
    facilityWatchers,
    bboxWatchers,
    bboardWatchers,
    cloverCellEvbWatchers,
    cloverCellArray1024Watchers,
    risWatchers,
    freqConverterGeneralWatcher,
    udboxWatchers,
    udmoduleWatchers,
    sgWatchers,
    powerDetectorWatchers,
  ]

  yield all(
    sagas.map(saga =>
      spawn(function* () {
        while (true) {
          try {
            // saga出错會退出 call 阻塞，然後 while 循環再次調用 call 重新執行 saga
            yield call(saga)
            break
          } catch (e) {
            devWarLog(e)
          }
        }
      })
    )
  )
}
