import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import InputGroupAppendDialog from 'src/components/Mix/InputGroupAppendDialog'
import CommonButton from 'src/components/Button/CommonButton'
import InputGroup from 'src/components/Input/InputGroup'

import { h4, h6 } from 'src/containers/operating/device/beamformers/style'
import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

import CIcon from 'src/assets/svg/unit/C'
import QIcon from 'src/assets/svg/unit/Q'

import {
  bboard_temperatureCompensationChange_watcher,
  bboard_TemperatureCompensation_setupClick_watcher,
} from 'src/redux/actions/beamformers/bboardAction'
import useGetUIControl from 'src/hooks/useGetUIControl'

const SetTemperature = () => {
  const dispatch = useDispatch()
  const { current } = useGetCurrentDeviceData()

  const sn = current?.sn
  const { rfMode } = current.data.deviceControl.common
  const { trC, trQ } = current.data.deviceControl.step[rfMode].compensation

  const { single: s_uiControl } = useGetUIControl(sn)
  const {
    temperatureCompensationButtonStatus,
    isTemperatureCompensationChanged,
  } = s_uiControl
  const isButtonLoading = temperatureCompensationButtonStatus === 'isRequest'
  const isButtonSuccess = temperatureCompensationButtonStatus === 'isSuccess'

  const { valueMax: cValueMax, currentValue: cCurrentValue } = trC
  const { valueMax: qValueMax, currentValue: qCurrentValue } = trQ

  const isChanged = isTemperatureCompensationChanged

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       State        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const [cFocus, setCFocus] = useState(false)
  const [cWarning, setCWarning] = useState(false)
  const [isCValid, setIsCValid] = useState(true)

  const [qFocus, setQFocus] = useState(false)
  const [qWarning, setQWarning] = useState(false)
  const [isQValid, setIsQValid] = useState(true)

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const handleCChange = ({ value, isValid }) => {
    setIsCValid(isValid)
    dispatch(
      bboard_temperatureCompensationChange_watcher({
        sn,
        trC: value,
        isValid,
      })
    )
  }

  const handleQChange = ({ value, isValid }) => {
    setIsQValid(isValid)
    dispatch(
      bboard_temperatureCompensationChange_watcher({
        sn,
        trQ: value,
        isValid,
      })
    )
  }

  const handleSetupClick = () => {
    if (!isButtonLoading && !isButtonSuccess)
      dispatch(
        bboard_TemperatureCompensation_setupClick_watcher({
          sn,
          trC: trC.currentValue,
          trQ: trQ.currentValue,
        })
      )
  }

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Props       ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const cStep = 1
  const qStep = 1

  const cInputProps = {
    // input params
    icon: <CIcon className='w-[10px]' />,
    unit: '',
    step: cStep,
    validMin: 0,
    validMax: cValueMax,
    decimalScale: 0,
    value: String(cCurrentValue),
    // progress bar params
    showProgress: false,
  }
  const qInputProps = {
    // input params
    icon: <QIcon className='w-[10px]' />,
    unit: '',
    step: qStep,
    validMin: 0,
    validMax: qValueMax,
    decimalScale: 1,
    value: String(qCurrentValue),
    // progress bar params
    showProgress: false,
  }

  const cWarningText = {
    WARNING_MIN: `The minimum value is ${0} 
        The system will use the minimum value.`,

    WARNING_MAX: `The maximum value is ${cValueMax}. 
        The system will use the maximum value.`,

    WARNING_STEP: `Value not allowed. Multiples of ${cStep} is required.`,
  }
  const qWarningText = {
    WARNING_MIN: `The minimum value is ${0} 
        The system will use the minimum value.`,

    WARNING_MAX: `The maximum value is ${qValueMax}. 
        The system will use the maximum value.`,

    WARNING_STEP: `Value not allowed. Multiples of ${qStep} is required.`,
  }

  const cDialogOverwriteElement = (
    <>
      <div className={columnBox}>
        <h6 className={h6 + ' uppercase'}>{rfMode} C</h6>
        <InputGroup
          className='w-[130px] h-[32px] '
          warning={true}
          disabled={true}
          {...cInputProps}
        />
        <h6 className={`${rangeText} text-yellow`}>Range 0 ~ {cValueMax}</h6>
      </div>
    </>
  )
  const qDialogOverwriteElement = (
    <>
      <div className={columnBox}>
        <h6 className={h6 + ' uppercase'}>{rfMode} Q</h6>
        <InputGroup
          className='w-[130px] h-[32px] '
          warning={true}
          disabled={true}
          {...qInputProps}
        />
        <h6 className={`${rangeText} text-yellow`}>Range 0 ~ {qValueMax}</h6>
      </div>
    </>
  )

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div className={container}>
      <h4 className={h4}>Temperature Compensation Control </h4>

      <h6 className={h6 + ' mt-1 mb-6'}>
        TX/RX C contributes about 1dB gain, and TX/RX Q contributes about 0.5 dB
        gain.
        <br /> Step range is 0~{cValueMax}.
      </h6>

      <div className='flex gap-x-4'>
        <div className={columnBox}>
          <h6 className={h6 + ' uppercase'}>{rfMode} C</h6>
          <InputGroupAppendDialog
            className='w-[130px]'
            {...cInputProps}
            // dialog params
            dialogText={cWarningText[cWarning]}
            placement={'right'}
            overwriteOffset={{ top: -20, left: 0 }}
            overwriteElements={cDialogOverwriteElement}
            // event params
            setFocus={result => setCFocus(result)}
            warning={cWarning}
            setWarning={result => setCWarning(result)}
            changeCallback={handleCChange}
          />
          <h6
            className={`${rangeText} text-light-5 ${
              (cWarning || !cFocus) && 'opacity-0'
            }`}>
            Range 0 ~ {cValueMax}
          </h6>
        </div>

        <div className={columnBox}>
          <h6 className={h6 + ' uppercase'}>{rfMode} Q</h6>
          <InputGroupAppendDialog
            className='w-[130px]'
            {...qInputProps}
            // dialog params
            dialogText={qWarningText[qWarning]}
            placement={'right'}
            overwriteOffset={{ top: -20, left: 0 }}
            overwriteElements={qDialogOverwriteElement}
            // event params
            setFocus={result => setQFocus(result)}
            warning={qWarning}
            setWarning={result => setQWarning(result)}
            changeCallback={handleQChange}
          />
          <h6
            className={`${rangeText} text-light-5 ${
              (qWarning || !qFocus) && 'opacity-0'
            }`}>
            Range 0 ~ {qValueMax}
          </h6>
        </div>

        <div className={columnBox + ' pb-[18px]'}>
          <CommonButton
            className='w-[130px] gap-x-1'
            type='general'
            disabled={!isChanged || !isCValid || !isQValid}
            loading={isButtonLoading}
            success={isButtonSuccess}
            onClick={handleSetupClick}>
            <span>Setup</span>
          </CommonButton>
        </div>
      </div>
    </div>
  )
}

export default SetTemperature

const container = `w-full py-4 px-3`
const columnBox = `flex flex-col gap-y-1 justify-end`
export const rangeText = `text-xs font-normal leading-[14px]`
