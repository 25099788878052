import { createAction } from '@reduxjs/toolkit'

export const cloverCell1024_initDevice_watcher = createAction(
  'deviceControl/cloverCell1024_initDevice_watcher'
)

export const cloverCell1024_common_rfModeChange_watcher = createAction(
  'deviceControl/cloverCell1024_common_rfModeChange_watcher'
)
export const cloverCell1024_common_externalControlChange_watcher = createAction(
  'deviceControl/cloverCell1024_common_externalControlChange_watcher'
)
export const cloverCell1024_common_freqSelect_watcher = createAction(
  'deviceControl/cloverCell1024_common_freqSelect_watcher'
)
export const cloverCell1024_common_sgFreqChange_watcher = createAction(
  'deviceControl/cloverCell1024_common_sgFreqChange_watcher'
)

export const cloverCell1024_channel_allPowerSwitch_watcher = createAction(
  'deviceControl/cloverCell1024_channel_allPowerSwitch_watcher'
)

export const cloverCell1024_steering_thetaPhiChange_watcher = createAction(
  'deviceControl/cloverCell1024_steering_thetaPhiChange_watcher'
)
export const cloverCell1024_steering_gainChange_watcher = createAction(
  'deviceControl/cloverCell1024_steering_gainChange_watcher'
)
export const cloverCell1024_steering_3DCameraChange_watcher = createAction(
  'deviceControl/cloverCell1024_steering_3DCameraChange_watcher'
)
export const cloverCell1024_steering_channelModeMaskClose_watcher =
  createAction('deviceControl/steeringChannelModeMaskClose')
